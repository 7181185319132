
import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import LoginForm from './LoginForm';

import mycourses from '../MyCourses/MyCourses';
import ForgotPassword from "./Forgotpassword";
import Registration from "./Registration";
import Translate from '../Shared/Translate';
import Cookies from 'universal-cookie';
import LanguagePopup from '../Shared/LanguagePopup';
import { updateRegisterError, updateUrlRegister } from "../../features/pageError/pageErrorSlice";
import { updateIsProject, updateUrl } from "../../features/project/projectSlice";
import { useDispatch, useSelector } from "react-redux";
import PageError from '../../components/Shared/PageError';
import CustumLoader from "../Shared/CustumLoader";
import { shouldRedirectTp, redirectUser, useIpAddress, createSession, logEveryoneOut } from './loginUtils';
import { variables } from "../Shared/Variables";
import { updateIndex, updateIsUserLoggedIn, updateNodeVal, callingDispose, updateVideo, updateIsDevice, updateIsMobile, updateVideoIndex, updateActiveStage1List, updateActiveStage4List, updateIsProgram, updateProgramCourses } from "../../features/CourseIndex/courseIndexSlice";
import PrimaryButton from '../Shared/Primarybtn';

//import { Beforeunload } from 'react-beforeunload';


const TokenLogin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const token = new URLSearchParams(location.search).get('token') || undefined;
    const tpId = Number(new URLSearchParams(location.search).get('tpId') || undefined);
    const userId = Number(new URLSearchParams(location.search).get('userId') || undefined);
    const [error, setError] = useState(undefined);
    const [isLoader, setIsLoader] = useState(true);
    const [isMultiUser, setMultiUser] = useState(false);
    const [userGroupsByUserIdTp, setUserGroupsByUserIdTp] = useState([]);
    const cookies = new Cookies();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Define your mobile screen width here
    const {ipAddress, ipStatus } = useIpAddress();
    const initialValues = { selectedUserGroup: -1 }
    const [formValues, setFormValues] = useState(initialValues);

    const [authenticated, setauthenticated] = useState(localStorage.getItem("login-userId"));

    const setLanguage = (languageId) => {
        switch (languageId) {
            case 1:
                return 'English';
            case 2:
                return 'Hindi';
            case 3:
                return 'Tamil';
            case 4:
                return 'Bengali';
            case 5:
                return 'Telugu';
            case 6:
                return 'Odiya';
            default:
                return '';
        }
    }

    useEffect(()=>{
        if(tpId && userId && token && !isNaN(tpId) && !isNaN(userId) && ['error', 'loaded'].includes(ipStatus)){
            loginUser();
        }
        const handleWindowSizeChange = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        cookies.set("language", "English");
        localStorage.setItem("prefferedLanguage", "English")
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    },[ipStatus])

    const loginUser = async () => {
        await fetch(variables.API_URL + 'api/VerifyToken', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token,
                userId,
                tpId
            })

        })
        .then(res => res.json())
        .then(async (result) => {
            // // console.log("result",result)
            await logEveryoneOut();
            if (result.isSuccess === true) {
                localStorage.clear();
                localStorage.setItem("JWTToken", result.jwtToken)
                if (result.dataList.length > 1) {
                    setMultiUser(true);
                    const userObjects = result.dataList || [];
                    const resultMap = new Map();
                    userObjects.forEach(user => {
                        const key = `${user.userId}-${user.tpId}`; // Create a unique key for each combination
                        // Check if the combination already exists in the map
                        if (!resultMap.has(key)) {
                            resultMap.set(key, { userId: user.userId, tpId: user.tpId, userObjects: [] });
                        }
                        // Push the current user object to the corresponding entry's userObjects array
                        resultMap.get(key).userObjects.push(user);
                    });
                    // Convert the map values to an array to get the final result
                    const resultUserGroupsByUserIdTp = Array.from(resultMap.values()) || [];
                    setUserGroupsByUserIdTp(resultUserGroupsByUserIdTp);
                    console.log("multi: ", resultUserGroupsByUserIdTp);
                } else {
                    if (result.dataList[0].userId != 0) {
                        // console.log("result.dataList[0] in login : ", result.dataList[0]);
                        var loggedInUser = result.dataList[0].userId;
                        var loggedInUserName = result.dataList[0].userName;
                        var loggedFirstName = result.dataList[0].firstName;
                        var prefferedLanguage = result.dataList[0].userPreferredLanguageId;
                        const tpId = result.dataList[0].tpId;
                        const tpBaseURL = result.dataList[0].tpBaseURL;
                        if(shouldRedirectTp(tpId)){
                            setIsLoader && setIsLoader(true)
                            redirectUser(tpId, loggedInUser, result.jwtToken, tpBaseURL)
                            setMultiUser(false);
                            return;
                        }
                        // var prefferedLanguage = 0;
                        localStorage.setItem("login-userId", loggedInUser);
                        localStorage.setItem("login-userName", loggedInUserName);
                        localStorage.setItem("login-firstName", loggedFirstName);
                        // temp line below need to remove
                        // localStorage.setItem("courseIdRegisterFor" , 147);
                        localStorage.setItem("prefferedLanguage", setLanguage(prefferedLanguage));
                        cookies.set('language', setLanguage(prefferedLanguage))
                        if (isMobile) {
                            localStorage.setItem("isDevice", "mobile");
                            dispatch(updateIsDevice('mobile'));
                        } else {
                            localStorage.setItem("isDevice", "desktop");
                            dispatch(updateIsDevice('desktop'));
                        }
                        //localStorage.setItem("userSelectedTestLan",prefferedLanguage)
                        dispatch(updateIndex('CO'));
                        dispatch(updateIsMobile(""));
                        dispatch(updateNodeVal([]));
                        dispatch(updateActiveStage1List([]));
                        dispatch(updateActiveStage4List([]));
                        dispatch(callingDispose(false));
                        dispatch(updateVideo(false));
                        dispatch(updateIsUserLoggedIn(true));
                        dispatch(updateVideoIndex("-1"));
                        dispatch(updateProgramCourses([]));
                        dispatch(updateIsProgram(false));
                        //cookies.set('username', result.dataList[0].userName);
                        // cookies.set('password', result.dataList[0].password);
                        createSession(cookies, ipAddress, isMobile).then(() => {
                            setauthenticated(loggedInUser);
                        })
                    }
                }
            }
        }, (error) => {
        })
    }

    function multiuserLogin() {
        const userGroup = userGroupsByUserIdTp[formValues.selectedUserGroup] || {}
        const userObjects = userGroup?.userObjects || [];
        if(!userGroup || !userObjects.length){
            console.log("Invalid user")
            return;
        }
        var tpId = userGroup.tpId;
        var userId = userGroup.userId;
        if(shouldRedirectTp(tpId)){
            setIsLoader && setIsLoader(true)
            redirectUser(tpId, userId, localStorage.getItem("JWTToken"), userGroup.tpBaseURL)
            setMultiUser(false);
            return;
        }
        var loggedInUser = userObjects[0].userId;
        var loggedInUserName = userObjects[0].userName;
        var loggedFirstName = userObjects[0].firstName;
        var prefferedLanguage = userObjects[0].userPreferredLanguageId;
        localStorage.setItem("login-userId", loggedInUser);
        localStorage.setItem("login-userName", loggedInUserName);
        localStorage.setItem("login-firstName", loggedFirstName);
        // temp line below need to remove
        // localStorage.setItem("courseIdRegisterFor" , 147);
        localStorage.setItem("prefferedLanguage", setLanguage(prefferedLanguage));
        cookies.set('language', setLanguage(prefferedLanguage))
        if (isMobile) {
            localStorage.setItem("isDevice", "mobile");
            dispatch(updateIsDevice('mobile'));
        } else {
            localStorage.setItem("isDevice", "desktop");
            dispatch(updateIsDevice('desktop'));
        }
        //localStorage.setItem("userSelectedTestLan",prefferedLanguage)
        dispatch(updateIndex('CO'));
        dispatch(updateIsMobile(""));
        dispatch(updateNodeVal([]));
        dispatch(updateActiveStage1List([]));
        dispatch(updateActiveStage4List([]));
        dispatch(callingDispose(false));
        dispatch(updateVideo(false));
        dispatch(updateIsUserLoggedIn(true));
        dispatch(updateVideoIndex("-1"));

        createSession().then(() => {
            setauthenticated(loggedInUser);
        })
        // logEveryoneOut().then(() => {

        // })
    }

    function onChangeValue(event) {
        // // console.log("working : ",event.target.value)
        formValues.selectedUserGroup = event.target.value;
        setFormValues(formValues);
    }

    if (authenticated) {
        return <Navigate to="/mycourses" />;
    }

    if(!tpId || !userId || !token || isNaN(tpId) || isNaN(userId) || error) {
        return <PageError/>
    }
    return <>
        <>
        {isMultiUser && (
            <>
                <div className="row">
                    <div className="popup-one popup-middle p-3 text-lg-left">
                        <img src="../../Assets/Linqskills_Icons/popup-close.svg" className="cursor-pointer float-end close-icon" onClick={()=>setMultiUser(false)}></img>
                        <div className="pb-3">
                            <div class="d-flex mb-3 p-0">
                                <div>
                                    <img src="../../Assets/Linqskills_Icons/select-user.svg" className="pe-2 mt-1"></img>
                                </div>
                                <div>
                                    <h6 className="mb-0 mt-2">

                                        <Translate textKey="Login012" />
                                    </h6>
                                </div>
                            </div>

                            <p className="h7 m-0 p-0">

                                <Translate textKey="userprofile021" />
                            </p>

                        </div>
                        {userGroupsByUserIdTp.map((userGroup, index) => {
                            const userObjects = userGroup.userObjects || [];
                            const courseNames = userObjects.map(val => val.courseName) || [];
                            return <div className="form-check mb-3" key={userObjects[0].userId}  >

                                <label className="form-check-label cursor-pointer" for={userObjects[0].userId}>
                                    <input className="form-check-input cursor-pointer" onChange={onChangeValue} type="radio" name="MultiUserId" value={index} id={userObjects[0].userId}></input>
                                    {userObjects[0].userName} - {courseNames.join(', ')}
                                </label>
                            </div>
                        })}
                        <div className="next-prev-padding d-flex mt-4">
                            <PrimaryButton type="button" onClick={multiuserLogin} disabled={formValues.selectedUserGroup === -1} textKey="Login007" />
                        </div>
                    </div>
                </div>

                <section className="modal overlay">
                </section>
            </>
        )}
        </>
        {isLoader && <CustumLoader message="Loading..." />}
    </>;
}

export default TokenLogin;