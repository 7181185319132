
import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import LoginForm from './LoginForm';

import mycourses from '../MyCourses/MyCourses';
import ForgotPassword from "./Forgotpassword";
import Registration from "./Registration";
import Translate from '../Shared/Translate';
import Cookies from 'universal-cookie';
import LanguagePopup from '../Shared/LanguagePopup';
import { updateRegisterError, updateUrlRegister } from "../../features/pageError/pageErrorSlice";
import { updateIsProject, updateUrl } from "../../features/project/projectSlice";
import { useDispatch, useSelector } from "react-redux";
import CustumLoader from "../Shared/CustumLoader";


//import { Beforeunload } from 'react-beforeunload';


const Loginpage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [authenticated, setauthenticated] = useState(localStorage.getItem("login-userId"));
    const [showForgotPassword, setForgotPassword] = useState(false);
    const [showRegistration, setShowRegistration] = useState(false);
    const [openLanguage, setopenLanguage] = useState(false);
    const [totalLanguages, setTotalLanguages] = useState([]);
    const [isActiveCourosol, setIsActiveCourosol] = useState(0);
    const [mailLangId, setMainLangId] = useState(1);
    const isProject = useSelector(state => state.project.isProject);
    const [toggle, setToggle] = useState(false);
    const cookies = new Cookies();
    const [isLoader, setIsLoader] = useState(false)
    //cookies.set('language', language, { path: '/' });
    var Lan = cookies.get('language');

    // console.log("cookies.get('language') in login page : ", cookies.get('language'));

    Lan = Lan != undefined ? Lan.substring(0, 2) : isProject.defaultLanguageID == 1 ? "EN" : isProject.defaultLanguageID == 2 ? "HI" : isProject.defaultLanguageID == 7 ? "GU" : "EN";
    // useEffect(()=>{
    // },[Lan])

    useEffect(() => {
        setToggle(!toggle)
    }, [Lan])


    function closeLanguage() {
        setopenLanguage(false)
    }

    const clearCacheData = () => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
        localStorage.clear();
        // alert("Complete Cache Cleared");
        return true
    };

    var registerGUId = ""
    const currentURL = window.location.href;

    // Do something with the URL
    // console.log('Current URL:', currentURL);

    if (currentURL == "http://localhost:3000/rgsa" || currentURL == "http://localhost:3000/rgsa#/" || currentURL == "https://betauatlms.linqskills.in/rgsa" || currentURL == "https://betauatlms.linqskills.in/#/rgsa" || currentURL == "https://betauatlms.linqskills.in/#rgsa" || currentURL.includes("http://localhost:3000/rgsa") || currentURL.includes("https://betauatlms.linqskills.in/rgsa")) {
        // alert("setting url rgsa");
        // setIsRgsa("rgsa");
        dispatch(updateUrl("rgsa"));

    } else {
        // dispatch(updateUrl(""));
        var url = window.location.href;
        // console.log("url hererere : ", url);
        // dispatch(updateUrlRegister(url));
        // https://betauatlms.linqskills.in/#/46BD66/1058C775-35C9-D49C-5345-60ED687CE01D
        registerGUId = url.indexOf('46BD66') > -1 ? url.substring(url.lastIndexOf('/') + 1, url.length) : "";
        // console.log("registerGUId : ", registerGUId)
        // clearCacheData();
    }


    useEffect(() => {
        const urlsToEnableRightClick = [
            'localhost',
            'beta'

            // Add more URLs as needed
        ];
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsActiveCourosol(prevCount => (prevCount + 1) % 4);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    const goToPrivacyPolicy = () => {
        // console.log("goToPrivacyPolicy called")
        navigate("/privacyPolicy")
    }

    if (authenticated) {
        return <Navigate to="/mycourses" />;
    }
    else {

        return (
            <>
                {isLoader && <CustumLoader message="Loading..." />}
                <div className="container-fluid">
                    <div className="row login-content">
                        <div className="container-fluid">
                            <div className="row login-content">
                            {/* <img src="../../Assets/Linqskills_Icons/Warning-popup-btn.svg"></img> */}

                                <div className=" bg-white p-0 col-xl-6 col-md-6 col-sm-12 col-xs-12 login-rightbox Sorder-xxl-last order-md-last order-sm-first order-xs-first">
                                    <div className="rightcontent pe-3 ps-3 bg-default">
                                        <div className="row ps-3 pe-3 pb-0 pt-3">
                                            <div className="col-md-12 p-0 animate-right">
                                                <img src="../../../assets/media/logos/linqskills.png"></img>
                                                <div className="language-select float-end text-uppercase" onClick={() => setopenLanguage(true)}>
                                                    <img src="../../Assets/Linqskills_Icons/Language_16.svg" className="pe-1 mb-1px"></img><span class="align-self-center cursor-pointer">{Lan}</span>
                                                    {/*<select className="select-lang" value={language} onChange={handleChange} >*/}
                                                    {/*    <option value="en" key="en">EN</option>*/}

                                                    {/*    {languageOptions.map((language) => (*/}
                                                    {/*        <option value={language.value} key={language.value}>{language.label}</option>*/}
                                                    {/*    ))}*/}
                                                    {/*</select>*/}

                                                </div>
                                            </div>
                                            <div id="popup-container" className="animate-right col-md-9 m-auto">

                                                {showForgotPassword ?
                                                    <ForgotPassword setForgotPassword={setForgotPassword} /> : (
                                                        showRegistration || registerGUId != "" ?
                                                            <Registration mailLangId={mailLangId} setMainLangId={setMainLangId} registerGUId={registerGUId} /> :
                                                            <LoginForm setauthenticated={setauthenticated} setForgotPassword={setForgotPassword} setShowRegistration={setShowRegistration} setIsLoader={setIsLoader}/>
                                                    )
                                                }
                                                {openLanguage ?
                                                    <LanguagePopup loginLangPopUp={true} mailLangId={mailLangId} setMainLangId={setMainLangId} closeEvent={closeLanguage} show={true} okEvent={closeLanguage} availableLanguages={totalLanguages} headerKey="Login014" hideDefaultCheck="true" />
                                                    : ""}
                                            </div>




                                        </div>





                                    </div>
                                    {/*   <Loginfooter/>*/}
                                    <div className="col-md-12 d-flex login-footer pt-1 mobilefooter ">
                                        <div className="col-lg-6 col-sm-12 ps-3 poweredby">
                                            <span><Translate textKey="Footer001" /> <span className="span-bold"><Translate textKey="Footer002" /></span></span>
                                            <span className="ps-3 footertext"><Translate textKey="Footer003" /> <span className="span-bold"><Translate textKey="Footer004" /></span></span>
                                        </div>
                                        <div className="col-6 text-right pe-3 mb-1 footerdetails display-desktop">
                                            <span className="ps-3"><Translate textKey="Copyright" /></span>
                                            <span className="ps-3 cursor-pointer" onClick={goToPrivacyPolicy}>Privacy Policy</span>
                                        </div>
                                        <div className="col-12 text-center pe-3 privacy-text footerdetails display-mobile border-top">
                                            <span className="ps-3 cursor-pointer" onClick={goToPrivacyPolicy}>Privacy Policy</span>
                                        </div>
                                    </div>
                                </div>

                                <div className=" animate-left col-xl-6 col-md-6 col-sm-12 col-xs-12 login-leftbox text-center order-xxl-first order-md-first order-sm-last order-xs-last">
                                    <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-indicators">
                                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className={isActiveCourosol == 0 ? "active" : ""} aria-label="Slide 1"></button>
                                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2" className={isActiveCourosol == 1 ? "active" : ""}></button>
                                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3" className={isActiveCourosol == 2 ? "active" : ""} aria-current="true"></button>
                                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4" className={isActiveCourosol == 3 ? "active" : ""} aria-current="true"></button>
                                        </div>

                                        <div className="carousel-inner">
                                            <div className={isActiveCourosol == 0 ? "carousel-item slider-ht active" : "carousel-item slider-ht"}>
                                                <img src="../../Assets/Linqskills_Icons/carousel-two.svg"></img>
                                                <div className="carousel-caption pb-0 d-md-block">
                                                    <h4>Your Path to Success Starts Here</h4>
                                                    <p>
                                                    We believe lifelong education transforms lives. Every interaction with our platform fosters personal growth and empowerment.
                                                    </p>
                                                </div>
                                            </div>

                                            <div className={isActiveCourosol == 1 ? "carousel-item slider-ht active" : "carousel-item slider-ht"}>
                                                <img src="../../Assets/Linqskills_Icons/carousel-three.svg"></img>
                                                <div className="carousel-caption pb-0 d-md-block">
                                                    <h4>
                                                        Enriching Lives Through Lifelong Learning Access
                                                    </h4>
                                                    <p>
                                                        Embark on a lifelong learning journey with us. Equip yourself with the tools and resources to excel in your personal and professional life, nurturing your potential and guiding you towards continuous success.
                                                    </p>
                                                </div>
                                            </div>

                                            <div className={isActiveCourosol == 2 ? "carousel-item slider-ht active" : "carousel-item slider-ht"}>
                                                <img src="../../Assets/Linqskills_Icons/carousel-one.svg"></img>
                                                <div className="carousel-caption d-md-block">
                                                    <h4>Navigating the Future of Education</h4>
                                                    <p>
                                                    We embrace the power of technology to enhance the teaching & learning experience.  Join us in the digital age of education.

                                                    </p>
                                                </div>
                                            </div>
                                            <div className={isActiveCourosol == 3 ? "carousel-item slider-ht active" : "carousel-item slider-ht"}>
                                                <img src="../../Assets/Linqskills_Icons/carousel-four.svg"></img>
                                                <div className="carousel-caption pb-0 d-md-block">
                                                    <h4>
                                                    Building Careers with Job Training
                                                    </h4>
                                                    <p>
                                                    Our mission is to nurture tomorrow's leaders by providing the education, skills, and inspiration needed for a brighter future.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default Loginpage;