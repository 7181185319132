import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import React, { useState, useEffect } from "react";
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from "react-redux";


export default function Traslate({ textKey,setvalue }) {
const cookies = new Cookies();
const isProject = useSelector(state => state.project.isProject);
// // console.log("isProject in translate : ",isProject);
const defaultLanguage = cookies.get('default-language')
// // console.log("defaultLanguage",defaultLanguage)
// // console.log("cookies.get('language') : ",cookies.get('language'));
i18n
.use(initReactI18next)
.use(LanguageDetector)
.init({

lng:defaultLanguage!="" && defaultLanguage!=undefined? cookies.get('default-language'): cookies.get('language') == "English" || isProject.defaultLanguageID == 1 ? "English" : cookies.get('language') == "Hindi" || isProject.defaultLanguageID == 2 ? "Hindi" : cookies.get('language') == "Gujarati" || isProject.defaultLanguageID == 7 ? "Gujarati" : "English",
fallbackLng: "English",
resources: {
English: {
translation: {
"Language001": "English",
"Language002": "Hindi",
"Language003": "Gujarati",

"Footer001": "Powered by",
"Footer002": "LinQ Skills",
"Footer003": "Developed by ",
"Footer004": "Schoolnet",
"Copyright": "All rights reserved. 2022",
"Login001": "LinQ Skills LMS",
"Login002": "Login ID",
"Login003": "Enter login ID or phone number or email",
"Login010": "Could not find the Login ID",
"Login004": "Password",
"ProfileLabel001" : "Old Password",
"ProfileLabel002" : "New Password",
"ProfileLabel003" : "Confirm Password",
"Profile001" : "Enter Old Password",
"Profile002" : "Enter New Password",
"Profile003" : "Enter Confirm Password",
"Login005": "Enter password",
"Login011": "Incorrect password",
"Login006": "Remember me on this device",
"Login007": "Login",
"Login008": "Forgot Password",
"Login009": "New to LMS? Join Now",
"Login012": "Select the User/ Login ID",
"Login013": "This phone number is linked to multiple courses. Choose the correct Login ID associated with the course you want to view.",
"Login014": "Select the preferred language",
"Login015": "Select the preferred language to view",
"Login016": "This email ID is linked to multiple courses. Choose the correct Login ID associated with the course you want to view.",
"Login017": "Enter phone number",

"Login018": "This is not a valid phone number",
"Login019": "OTP Number",
"Login020": "Enter OTP number",
"Login021": "Change Phone number",
"Login022": "Rashtra Gram Seva Abhiyan",
"Login023": "Select your level",
"Login024": "Select your role",

"Login025": "Select District",
"Login026": "Select Block",
"Login027": "Select Village",


"ProfileSucccessLanguage" : "Language updated successfully",
"ProfileSucccessMobile" : "Mobile number updated successfully",
"ProfileSucccessEmail" : "Email updated successfully",
"ProfileSucccessPassword" : "Password updated successfully",
"ProfileSucccessProfile" : "Profile details updated successfully",
"wrongOtpEntered" : "Wrong otp entered",
"emailAlreadyInUse" : "Email ID is already used by another user. Kindly use a different email ID.",
"numberAlreadyInUse" : "The phone number is already used by another user. Kindly use a different phone number.",
"forgotpassword001": "Find your password",
"forgotpassword002": "Phone number or email ID",
"forgotpassword003": "Enter phone number or email ID",
"forgotpassword004": "Enter registered phone number or email ID",
"forgotpassword007": "Could not find the phone number",
"forgotpassword008": "Could not find the email ID",
"forgotpassword005": "SEND DETAILS",
"forgotpassword006": "BACK TO LOGIN",
"forgotpassword009": "Login details shared on registered email ID",
"forgotpassword010": "Login details shared on registered phone number.",
"forgotpassword011": "This is not a valid phone number or email ID"
,"forgotpassword012": "Login details shared on registered phone number and email ID."
, "registration001":"Video Tutorial: How to Register"
, "registration002":"Select the preferred language to view the video"

, "registration040": "Register on LinQ Skills LMS"
, "registration003": "Country"
, "registration004": "Phone number"
, "registration005": "Enter your phone number"
, "registration055": "Enter 10 digit number"
, "profilePhonelength": "Enter your 10 digit phone number"
, "profilePhoneValid": "Enter valid phone number"
, "registration011": "Please enter a valid phone number"
, "registration006": "Email ID"
, "registration007": "Enter your email ID"
, "registration008": "Email ID is mandatory if 'Country' is other than 'India'.It will be used for all course- related communication like login, notification, etc."
, "registration012": "Please enter a valid email ID"
, "registration009": "CONTINUE"
, "registration010": "How to Register"
, "registration013": "User Already Exists"
, "registration014": "The phone number is already registered with another course/Login ID. Do you wish to register for this course using the same phone number?"
, "registration049": "This Email ID is register with another user. Do you wish to continue using same Email ID ?"
, "registration015": "Select"

, "registration016": "CANCEL"
, "registration017": "Phone OTP"
, "registration018": "Enter 4 digit OTP number"
, "registration019": "Enter the OTP code sent via SMS"
, "registration023": "Incorrect OTP code.Please enter as sent via SMS"
, "registration022": "Enter the OTP code sent via email"
, "registration024": "Incorrect OTP code.Please enter as sent via email"
, "registration020": "Not Received OTP.Resend again?"
, "registration021": "STEP 1"
, "registration59": "OK"
,"registration025":"Full Name"
,"registration026":"Enter your name"
,"registration056":"A minimum of 3 letters is necessary. Only special characters like - ! ` and English alphabets are permitted. - A minimum of 3 letters is necessary. Only special characters like -, !, ', and English letters are permitted."
,"registration031":"Name cannot contain a number value"
,"registration027":"Enter minimum 8 digit password"
,"registration032":"The passwords do not match"
,"registration028":"Re enter password"
,"registration029":"Re enter the same password as given above"
,"registration030": "The password must include at least one uppercase letter, one lowercase letter, one special character, and one number. Only English alphabets are allowed."
,"registration033": "State"
,"registration034": "Select State"
,"registration035": "City"
,"registration036": "Enter the city name"
,"registration037": "Education"
,"registration038": "Select your highest qualification"
,"registration039": "I agree, all details are accurate"
,"registration041": "Welcome to LinQ Skills LMS"
,"registration042": "Successfully registered on LinQ Skills LMS"
,"registration043": "Your login credentials are shared below. "
,"registration044": "We have also sent the login details via SMS"
,"registration050": "We have also sent the login details via email"
,"registration051": "We have also sent the login details via email"
,"registration045": "Login ID is"
,"registration046": "Password is"
,"registration047": "Note: You can also use your phone number to login"
,"registration048": "Login To LMS"
,"registration054": "Email OTP"
,"registration057": "You have already enrolled for this course"
,"registration058": "Proceed to Login"

,"coursecontent001":"Video Tutorial: How to view course content"
,"mycourse001": "Video Tutorial: Tour of LinQ Skills LMS"
,"eligibility001": "Video Tutorial: How to attempt Test",
"eligibility002":"The assessment requires a unique email ID for each user. The email you provided is already in use by another user. Please update your email ID on the edit profile page."
,"eligibility003": "Assessment Score"
,"eligibility004":"The test score will be generated and shared with your college."
,"eligibility005": "To find out your score, please contact the college admin."
,"eligibility006":"The test score will be generated and shared with your college. To find out your score, please contact the college admin."

,"eligibility007": "The test window is closed. The last date to complete the test was"
,"eligibility008": "Acceptance Status"
,"eligibility009": "You have been shortlisted for the next round of the placement process."


,"userprofile001": "Edit Password"
,"userprofile002": "Update Contact"
,"userprofile003": "User Preferences"
,"userprofile004": "OTP sent. Valid till 10 mins."
,"userprofile005": "Participant / Student"
,"userprofile006": "Remove Photo"
,"userprofile007": "Upload Photo"
,"userprofile008": "Save and Update"
,"userprofile009": "Choose your profile photo and then click on Upload Photo to submit Format Supported JPG/PNG/GIF.Size not more than 2MB"
,"userprofile010": "Personal Details"
,"userprofile011": "Profile Details"
,"userprofile012": "Medium of Instructions"
,"userprofile013": "Set this option as my default language for viewing course content on LinQ LMS"
,"userprofile014": "Choose a file and then click on “Upload File"
,"userprofile015": "Image Gallery"
,"userprofile016": "Take Photo"
,"userprofile017": "Upload File"
,"userprofile018": "Formats allowed: PNG, JPG, JPEG, GIF Upload file of any size"
,"userprofile019": "Edit"
,"userprofile020": "Verify and Update"
,"userprofile021": "Send OTP"
,"userprofile022": "Profile Settings"
,"userprofile022": "OTP"

,"postregform001": "Personal"
,"postregform002": "Organisation"
,"postregform003": "Gender Preference"
,"postregform004": "Select your gender"
,"postregform005": "This will enable us to suggest courses tailored to specific genders as part of our offerings."
,"postregform006": "Language Preference"
,"postregform007": "Select language"
,"postregform008": "Make this option my default language for viewing course content on LinQ LMS."
,"postregform009": "UDISE Code"
,"postregform010": "Enter the Code"
,"postregform011": "Please input the school identification code issued by the State Board."
,"postregform012": "Cannot remember the code"
,"postregform013": "Code does not exist"
,"postregform014": "There was a connection break, please resbmit the code."
,"postregform015": "Select the State"
,"postregform016": "Select State"
,"postregform017": "Select the State where your school is situated."
,"postregform018": "Select the District"
,"postregform019": "Select District"
,"postregform020": "Select the District where your school is situated."
,"postregform021": "Select the School Name"
,"postregform022": "Select School Name"
,"postregform023": "Select your school's name to obtain the school identification code."
,"postregform024": "If your school name is not available in the provided list, contact our administrative team."
,"postregform025": "Enter your full name"
,"postregform026": "This name will be displayed as your profile name and also be used in the certificate. A minimum of 3 letters is necessary. Only special characters like - ! ` and English alphabets are permitted. - Only special characters like -, !, ` and English alphabets are permitted. - add commas and replace alphabets with letters."

,"Policy001": "Privacy Policy & Conditions"

,"mycourse002": "My Courses"
,"mycourse003": "In progress"
,"mycourse004": "Welcome,"
,"mycourse005": "Start Course"
,"mycourse006": "Resume Course"
,"mycourse007": "View Certificate"
,"mycourse008": "Complete Profile"
,"mycourse010": "Find a course you want to learn"
,"mycourse011": "courses available now"
,"mycourse012": "My Proficiency Test"
,"mycourse013": "Measure your readiness and proficiency with tailored course, career, and employability tests."
,"mycourse0014": "View Course"


,"postregform025": "Enter You Full Name"
,"postregform026": "This name will be displayed as your profile name and also be used in the certificate. A minimum of 3 letters is necessary. Only special characters like - ! ` and English alphabets are permitted."
,"postregform027": "Please ensure you enter the correct email ID, as it will be used to send alerts and important messages."
,"postregform028": "Thanks for your time!"

,"postregform029": "Before you hit that proceed button, give your submission a once-over and let the learning begin!"
,"postregform030": "Go Back"
,"postregform031": "Proceed"

,"postregform032": "Select State where you are currently residing"
,"postregform033": "Select the District where you are currently residing"
,"postregform034": "City"
,"postregform035": "Enter city name"
,"postregform036": "Please enter the name of the city where you currently live"
,"postregform037": "Pincode"
,"postregform038": "Enter the pincode"
,"postregform039": "Enter the pincode (postal code) of your current location"
,"postregform040": "Highest Qualification"
,"postregform041": "Select the qualification"
,"postregform042": "This will enable to suggest courses tailored to your qualification as part of our offerings"
,"postregform043": "Current Designation"
,"postregform044": "Select your designation"
,"postregform045": "This will enable us customise LMS feature as per your current designation"
,"postregform046": "Classes you are currently teaching"
,"postregform047": "Choose the classes"
,"postregform048": "This will help us recommend courses basis the classes you teach"
,"postregform049": "Subjects you are currently instructing"
,"postregform050": "Choose the subjects"
,"postregform051": "This will help us recommend courses basis the subject you teach"
,"postregform052": "Select the state where your college is situated"
,"postregform053": "Select the city where your college is situated"
,"postregform054": "College Name"
,"postregform055": "Select college name"
,"postregform056": "Select your college's name to map to get access to your college specfic courses"
,"postregform057": "If your college name is not available in the provided list, contact our administrative team"
,"postregform058": "Department"
,"postregform059": "Choose the department"
,"postregform060": "This will enable us forward valuable inputs as per your department"
,"postregform064": "Semester"
,"postregform065": "Choose the semester"
,"postregform066": "This will enable us to suggest courses tailored to your semester as part of our offerings"
,"postregform061": "Ongoing/Passed"
,"postregform062": "Ongoing"
,"postregform063": "This will allow us to recommend a career path specifically tailored to you once you graduate from college"
,"postregform067": "Specilisation"
,"postregform068": "Choose your specilisation"
,"postregform069": "This will allow us to recommend a personliased path specifically tailored to your interests and skills as you progress in your career"
,"postregform070": "Year of Passing"
,"postregform071": "Choose year"
,"postregform072": "This will enable us to update your training and career timeline"

,"courseindex001": "Continue with Last Session"
,"courseindex002": "Go to Topic"
,"courseindex003": "Content"
,"courseindex004": "Online Lectures"
,"courseindex005": "Certificate"

,"courseindex006": "Curriculum"
,"courseindex007": "Self Study"
,"courseindex008": "Assessments"
,"courseindex009": "Video Content"
,"courseindex010": "Resource Content"

,"coursecontent002": "Sort by Session Type"
,"coursecontent003": "All Sessions"
,"coursecontent004": "Self Study Sessions"
,"coursecontent005": "Online Lecture Sessions"
,"coursecontent006": "Assessment Sessions"

,"topic001": "Previous"
,"topic002": "Next"
,"certificate001": "The certificate will be available after"
,"certificate002": "The certificate will be available after the course is completed"
,"certificate003": "Download Certificate"

,"Chatbot001":"I'm your personal learning assistant. I'll do my best to help you learn better."
,"Chatbot002":"Hello"
,"Chatbot003":"Here are some resources to help with your query:"
,"Chatbt004":"RGSA Chatbox"

}
},
Hindi: {

translation: {
"Language001": "अंग्रेज़ी",
"Language002": "हिंदी",
"Language003": "गुजराती",

"Footer001": "द्वारा संचालित",
"Footer002": "लिंक्यु स्किल्स",
"Footer003": "द्वारा विकसित",
"Footer004": "स्कूलनेट",
"Copyright": "सर्वाधिकार सुरक्षित 2022",

"Login001": "LinQ Skills LMS",
"Login002": "लॉगिन आईडी",
"Login003": "लॉगिन आईडी या फोन नंबर या ईमेल दर्ज करें",
"Login010": "लॉगिन आईडी मौजूद नहीं है",
"Login004": "पासवर्ड",
"Login005": "पासवर्ड दर्ज करें",
"Login011": "पासवर्ड गलत है",
"Login006": "इस डिवाइस पर मुझे याद रखें",
"Login007": "लॉगिन",
"Login008": "फॉरगॉट पासवर्ड",
"Login009": "एलएमएस के लिए नए हैं? अभी रजिस्टर करें",
"Login012": "यूजर/लॉगिन आईडी का चयन करें",
"Login013": "यह फोन नंबर कई कोर्स से जुड़ा है। आप जिस कोर्स को देखना चाहते हैं, उससे जुड़ी सही लॉगिन आईडी चुनें।",
"Login014": "भाषा का चयन करें",
"Login015": "देखने के लिए पसंदीदा भाषा का चयन करें",
"Login016": "यह ईमेल आईडी कई कोर्स से जुड़ी है। आप जिस कोर्स को देखना चाहते हैं, उससे जुड़ी सही लॉगिन आईडी चुनें।.",

"Login025": "जिला चुनें",
"Login026": "ब्लॉक चुनें",
"Login027": "गांव का चयन करें",

"forgotpassword001": "अपना पासवर्ड खोजें"
, "forgotpassword002": "फ़ोन नंबर या ईमेल आईडी"
, "forgotpassword003": "फ़ोन नंबर या ईमेल आईडी दर्ज करें"
, "forgotpassword004": "रजिस्टर्ड फ़ोन नंबर या ईमेल आईडी दर्ज करें"
, "forgotpassword007": "फ़ोन नंबर नहीं मिल रहा है"
, "forgotpassword008": "ईमेल आईडी नहीं मिल रहा है"
, "forgotpassword005": "विवरण भेजें"
, "forgotpassword006": "लॉगिन पेज पर वापस जाएं"
, "forgotpassword009": "लॉगिन की विवरण रजिस्टर्ड ईमेल आईडी पर भेजा गया है"
, "forgotpassword010": "लॉगिन की विवरण रजिस्टर्ड फ़ोन नंबर पर भेजा गया है"
, "forgotpassword011": "यह एक मान्य फ़ोन नंबर या ईमेल आईडी नहीं है"
, "forgotpassword012": "लॉगिन विवरण रजिस्टर्ड फ़ोन नंबर और ईमेल आईडी पर भेजा गया है"

, "registration001":"वीडियो ट्यूटोरियल: रजिस्टर कैसे करें"
, "registration002":"वीडियो देखने के लिए भाषा का चयन करें"
, "registration040":"लिक्यु स्किल्स एलएमएस पर रजिस्टर करें"
, "registration003":"देश"
, "registration004":"फ़ोन नंबर "
, "registration005":"अपना फ़ोन नंबर दर्ज करें"
, "registration055":"10 अंकों की संख्या दर्ज करें"
, "registration011":"कृपया कोई मान्य फ़ोन नंबर दर्ज करें"
, "registration006":"ईमेल आईडी"
, "registration007":"अपनी ईमेल आईडी दर्ज करें"
, "registration008": `यदि आपका देश "भारत" के अलावा अन्य है तो ईमेल आईडी अनिवार्य है।इसका उपयोग पाठ्यक्रम से संबंधित सभी संचार जैसे लॉगिन, अधिसूचना आदि के लिए किया जाएगा।`
, "registration012":"कृपया एक मान्य ईमेल आईडी दर्ज करें"
, "registration009":"जारी रखें"
, "registration010":"कैसे रजिस्टर करें"
, "registration013":"उपयोगकर्ता पहले से मौजूद है"
, "registration014":"फ़ोन नंबर पहले से ही किसी अन्य कोर्स/लॉगिन आईडी के साथ रजिस्टर्ड है। क्या आप उसी फ़ोन नंबर का उपयोग करके इस कोर्स के लिए रजिस्टर करना चाहते हैं?"
, "registration049":"ईमेल आईडी किसी अन्य उपयोगकर्ता के साथ रजिस्टर्ड है। क्या आप उसी ईमेल आईडी का उपयोग करके लॉग इन करना चाहते हैं?"
, "registration015":"हाँ"
, "registration016":"रद्द करें"
, "registration017":"फ़ोन ओटीपी"
, "registration018":"4 अंकों का ओटीपी नंबर दर्ज करें"
, "registration019":"एसएमएस के माध्यम से भेजा गए ओटीपी कोड दर्ज करें"
, "registration023":"गलत ओटीपी कोड। कृपया एसएमएस के माध्यम से भेजे गए कोड के अनुसार दर्ज करें"
, "registration022":"ईमेल द्वारा भेजा गया ओटीपी कोड दर्ज करें"
, "registration024":"गलत ओटीपी कोड। कृपया ईमेल के माध्यम से भेजे गए कोड के अनुसार दर्ज करें"
, "registration020":"ओटीपी प्राप्त नहीं हुआ। फिर से भेजें?"
, "registration021": "चरण 1"
, "registration025":"पूरा नाम"
, "registration026":"अपना नाम दर्ज करें"
,"registration056":"कम से कम 3 अक्षर आवश्यक हैं। केवल विशेष चिह्न जैसे कि - ! ` और ENGLISH alphabets की अनुमति है।"
, "registration031":"नाम में कोई संख्या मान्य नहीं हो सकता"
, "registration027":"न्यूनतम 8 अंकों का पासवर्ड दर्ज करें"
, "registration032":"पासवर्ड मेल नहीं कर रहा हैं"
, "registration028":"पासवर्ड पुनः दर्ज करें"
, "registration029":"ऊपर दिए गए पासवर्ड को पुनः दर्ज करें"
, "registration030":"पासवर्ड में कम से कम एक अपरकेस अक्षर, एक लोअरकेस अक्षर, एक विशेष अक्षर और एक संख्या शामिल होनी चाहिए। केवल अंग्रेजी वर्णमाला की अनुमति है।"
, "registration033":"राज्य"
, "registration034":"राज्य का चयन करें"
, "registration035":"शहर"
, "registration036":"शहर का नाम दर्ज करें"
, "registration037":"शिक्षा"
, "registration038":"अपनी उच्चतम योग्यता का चयन करें"
, "registration039":"मैं सहमत हूं, सभी विवरण सही हैं"
, "registration041":"लिक्यु स्किल्स एलएमएस में आपका स्वागत है"
, "registration042":"आप लिक्यु स्किल्स एलएमएस पर सफलतापूर्वक रजिस्टर्ड हो गए हैं"
, "registration043":"आपका लॉगिन विवरण नीचे साझा किया गया है"
, "registration044":"हमने एसएमएस के माध्यम से लॉगिन विवरण भी भेजा है"
, "registration050":"हमने ईमेल के माध्यम से भी लॉगिन विवरण भेजा है"
, "registration051":"हमने एसएमएस और ईमेल के माध्यम से भी लॉगिन विवरण भेजा है"
, "registration045":"लॉगिन आईडी है"
, "registration046":"पासवर्ड है"
, "registration047":"नोट: आप लॉगिन करने के लिए अपने फ़ोन नंबर का भी उपयोग कर सकते हैं"
, "registration048":"सीखना शुरू करें"
, "registration054": "ईमेल ओटीपी"
, "registration057": "आपने पहले ही इस पाठ्यक्रम के लिए नामांकन कर लिया है"
, "registration058": "लॉगिन पेज पर जाएं"

,"coursecontent001":"वीडियो ट्यूटोरियल: कैसे कोर्स सामग्री देखें"
,"mycourse001": "वीडियो ट्यूटोरियल: LinQ Skills LMS का पूर्वाभ्यास"
,"eligibility001": "वीडियो ट्यूटोरियल: टेस्ट का प्रयास कैसे करें"
,"eligibility002":"असेसमेंट के लिए हर यूज़र का ईमेल आईडी यूनिक होना चाहिए। आपने जो ईमेल दिया है, वह पहले से ही किसी और यूज़र द्वारा उपयोग किया जा रहा है। कृपया अपना ईमेल आईडी एडिट प्रोफाइल पेज पर अपडेट करें।"

,"eligibility003": "मूल्यांकन स्कोर"
,"eligibility004":"आपका टेस्ट स्कोर तैयार कर के आपके कॉलेज को भेज दिया जाएगा।"
,"eligibility005": "अपना स्कोर जानने के लिए कृपया कॉलेज प्रशासन से संपर्क करें।"
,"eligibility006":"आपका टेस्ट स्कोर तैयार कर के आपके कॉलेज को भेज दिया जाएगा। अपना स्कोर जानने के लिए कृपया कॉलेज प्रशासन से संपर्क करें।"

,"eligibility007": "The test window is closed. The last date to complete the test was"
,"eligibility008": "Acceptance Status"
,"eligibility009": "You have been shortlisted for the next round of the placement process."


,"userprofile001": "पासवर्ड संपादित करें"
,"userprofile002": "संपर्क अद्द्तन करें"
,"userprofile003": "उपयोगकर्ता की पसंद"
,"userprofile004": "ओटीपी भेजा गया। 10 मिनट तक मान्य रहेगा।"
,"userprofile005": "प्रतिभागी / छात्र"
,"userprofile006": "फ़ोटो हटाएँ"
,"userprofile007": "फोटो अपलोड करें"
,"userprofile008": "सहेजें एवं अद्द्तन करें"
,"userprofile009": "अपना प्रोफ़ाइल फ़ोटो चुनें और फिर सबमिट करने के लिए फ़ोटो अपलोड करें पर क्लिक करें। छवि प्रारूप की अनुमति: JPG/PNG/GIF। आकार 2MB से अधिक नहीं।"
,"userprofile010": "निजी विवरण"
,"userprofile011": "प्रोफ़ाइल विवरण"
,"userprofile012": "शिक्षा का माध्यम"
,"userprofile013": "LinQ LMS पर पाठ्यक्रम सामग्री देखने के लिए इस विकल्प को मेरी डिफ़ॉल्ट भाषा के रूप में सेट करें"
,"userprofile014": "फ़ाइल चुनें और फिर फ़ाइल अपलोड करें पर क्लिक करें"
,"userprofile015": "छवि गैलरी"
,"userprofile016": "फोटो लें"
,"userprofile017": "फ़ाइल अपलोड करें"
,"userprofile018": "छवि प्रारूप की अनुमति: JPG/PNG/GIF।"
,"userprofile019": "संपादित करें"
,"userprofile020": "सत्यापित और अद्द्तन करें"
,"userprofile021": "ओटीपी भेजें"
,"userprofile022": "प्रोफ़ाइल सेटिंग्स"




,"postregform001": "निजी"
,"postregform002": "संगठन"
,"postregform003": "जेंडर वरीयता"
,"postregform004": "जेंडर का चयन करें"
,"postregform005": "ये हमारे ऑफरिंग्स का हिस्सा बनकर विशेष लैंगिकताओं के अनुकूल कोर्सेज सुझाने में हमारी मदद करेगा।"
,"postregform006": "भाषा वरीयता"
,"postregform007": "लैंग्वेज़ चुनें"
,"postregform008": "LinQ LMS पर पाठ्यक्रम सामग्री देखने के लिए इस विकल्प को मेरी डिफ़ॉल्ट भाषा बनाएं"
,"postregform009": "यू.डी.आई.एस.ई कोड"
,"postregform010": "कृपया कोड दर्ज करें"
,"postregform011": "कृपया राज्य बोर्ड द्वारा जारी किए गए विद्यालय पहचान संख्या दर्ज करें"
,"postregform012": "कोड याद नहीं रख सकते हैं"
,"postregform013": "कोड मौजूद नहीं है"
,"postregform014": "कनेक्शन ब्रेक हो गया था, कृपया कोड फिर से सबमिट करें"
,"postregform015": "राज्य का चयन करें"
,"postregform016": "राज्य का चयन करें"
,"postregform017": "उस राज्य का चयन करें जहां आपका विद्यालय स्थित है"
,"postregform018": "जिले का चयन करें"
,"postregform019": "जिले का चयन करें"
,"postregform020": "उस जिले का चयन करें जहां आपका विद्यालय स्थित है"
,"postregform021": "विद्यालय का नाम चुनें"
,"postregform022": "विद्यालय का नाम चुनें"
,"postregform023": "अपने विद्यालय का नाम चुनें और विद्यालय की पहचान संख्या प्राप्त करें"
,"postregform024": "अगर आपके विद्यालय का नाम दी गई सूची में उपलब्ध नहीं है, तो हमारे प्रशासनिक टीम से संपर्क करें"

,"Policy001": "गोपनीयता नीति और शर्तें"

,"postregform025": "अपना पूरा नाम दर्ज करें।"
,"postregform026": "यह नाम आपके प्रोफ़ाइल नाम के रूप में दिखाया जाएगा और सर्टिफिकेट में भी इस्तेमाल होगा। कम से कम 3 अक्षर आवश्यक हैं। केवल विशेष चिह्न जैसे कि - ! ` और अंग्रेजी वर्णमाला की अनुमति है।"
,"postregform027": "कृपया सही ईमेल आईडी दर्ज करें, क्योंकि यह अलर्ट्स और महत्वपूर्ण संदेश भेजने के लिए उपयोग किया जाएगा।"
,"postregform028": "आपके समय के लिए धन्यवाद!"

,"postregform029": "कोर्स बटन दबाने से पहले, अपनी सबमिशन एक बार चेक कर लो और सीखना शुरू करो!"

,"postregform030": "पीछे जाइए"
,"postregform031": "आगे बढ़ें"

,"postregform032": "Select State where you are currently residing"
,"postregform033": "Select the District where you are currently residing"
,"postregform034": "City"
,"postregform035": "Enter city name"
,"postregform036": "Please enter the name of the city where you currently live"
,"postregform037": "Pincode"
,"postregform038": "Enter the pincode"
,"postregform039": "Enter the pincode (postal code) of your current location"
,"postregform040": "Highest Qualification"
,"postregform041": "Select the qualification"
,"postregform042": "This will enable to suggest courses tailored to your qualification as part of our offerings"
,"postregform043": "Current Designation"
,"postregform044": "Select your designation"
,"postregform045": "This will enable us customise LMS feature as per your current designation"
,"postregform046": "Classes you are currently teaching"
,"postregform047": "Choose the classes"
,"postregform048": "This will help us recommend courses basis the classes you teach"
,"postregform049": "Subjects you are currently instructing"
,"postregform050": "Choose the subjects"
,"postregform051": "This will help us recommend courses basis the subject you teach"
,"postregform052": "Select the state where your college is situated"
,"postregform053": "Select the city where your college is situated"
,"postregform054": "College Name"
,"postregform055": "Select college name"
,"postregform056": "Select your college's name to map to get access to your college specfic courses"
,"postregform057": "If your college name is not available in the provided list, contact our administrative team"
,"postregform058": "Department"
,"postregform059": "Choose the department"
,"postregform060": "This will enable us forward valuable inputs as per your department"
,"postregform064": "Semester"
,"postregform065": "Choose the semester"
,"postregform066": "This will enable us to suggest courses tailored to your semester as part of our offerings"
,"postregform061": "Ongoing/Passed"
,"postregform062": "Ongoing"
,"postregform063": "This will allow us to recommend a career path specifically tailored to you once you graduate from college"
,"postregform067": "Specilisation"
,"postregform068": "Choose your specilisation"
,"postregform069": "This will allow us to recommend a personliased path specifically tailored to your interests and skills as you progress in your career"
,"postregform070": "Year of Passing"
,"postregform071": "Choose year"
,"postregform072": "This will enable us to update your training and career timeline"


,"mycourse002": "मेरे कोर्स"
,"mycourse003": "प्रगति पर है"
,"mycourse004": "स्वागत है,"
,"mycourse005": "कोर्स शुरू करें"
,"mycourse006": "कोर्स फिर से शुरू करें"
,"mycourse007": "प्रमाणपत्र देखें"
,"mycourse008": "रजिस्ट्रेशन पूरी करें"
,"mycourse009": "टेस्ट पूरी करें"
,"mycourse012": "मेरे प्रोफिशिएंसी टेस्ट्स"
,"mycourse013": "अपने ज्ञान और क्षमताओं को कोर्स, करियर, और रोजगार के परीक्षणों के साथ मापें।"
,"mycourse0014": "कोर्स देखें"


,"courseindex001": "अंतिम सत्र से कंटिन्यू करें"
,"courseindex002": "टॉपिक पर जाएं"
,"courseindex003": "विषय- वस्तु"
,"courseindex004": "ऑनलाइन लेक्चर्स"
,"courseindex005": "प्रमाणपत्र"
,"courseindex006": "पाठ्यक्रम"
,"courseindex007": "स्वाध्याय"
,"courseindex008": "आकलन"
,"coursecontent002": "सत्र प्रकार के द्वारा क्रमबद्ध करें"
,"coursecontent003": "सभी सत्र"
,"coursecontent004": "स्वाध्याय सत्र"
,"coursecontent005": "ऑनलाइन लेक्चर्स सत्र"
,"coursecontent006": "आकलन सत्र"
,"courseindex009": "वीडियो सामग्री"
,"courseindex010": "संसाधन सामग्री"
,"certificate002": "कोर्स पूरा होने के बाद प्रमाणपत्र उपलब्ध होगा"
}
},
Gujarati: {
translation: {
"Language001": "અંગ્રેજી",
"Language002": "हिंदी",
"Language002": "ગુજરાતી",

"Footer001": "द्वारा संचालित",
"Footer002": "लिक्यु स्किल्स",
"Footer003": "द्वारा विकसित",
"Footer004": "स्कूलनेट",
"Copyright": "सभी अधिकार सुरक्षित. 2022",

"Login001": "લિક્યુ સ્કિલ્સ એલએમએસ",
"Login002": "લોગઇન આઈડી",
"Login003": "લોગઇન આઈડી અથવા ફોન નંબર અથવા ઇમેઇલ દાખલ કરો",

"mycourse010": "તમારા પસંદગીના વિષય પર ક્લિક કરો",
"mycourse011": "ઉપલબ્ધ અભ્યાસક્રમ",

"Login004": "પાસવર્ડ",
"Login005": "પાસવર્ડ દાખલ કરો",

"Login006": "આ ડિવાઈસ પર મને યાદ રાખો",
"Login007": "લોગઇન",
"Login008": "પાસવર્ડ ભૂલી ગયા",
"Login009": "એલએમએસ માટે નવાં છો? હવે રજિસ્ટર કરો",
"Login010": "લોગિન આઈડી મૌજૂદ નથી",
"Login011": "અમાન્ય પાસવર્ડ",
"Login012": "યુઝર/લોગઇન આઈડી પસંદ કરો",
"Login013": "આ ફોન નંબર ઘણા કોર્સથી જોડાયું છે. તમારી જોવાઈતા કોર્સ સાથે જોડાયેલ લોગઇન આઈડી પસંદ કરો",
"Login014": "ભાષા પસંદ કરો",
"Login015": "જોવા માટે ભાષાનો પસંદગી કરો",
"Login016": "આ ઇમેઇલ આઈડી ઘણા કોર્સથી જોડાયું છે. તમારી જોવાઈતા કોર્સ સાથે જોડાયેલ લોગઇન આઈડી પસંદ કરો",
"Login017": "ફોન નંબર દાખલ કરો",
"Login018": "આ માન્ય ફોન નંબર નથી",
"Login019": "ઓટીપી નંબર",
"Login020": "ઓટીપી દાખલ કરો",
"Login021": "ફોન નંબર બદલો",
"Login022": "રાષ્ટ્રીય ગ્રામ સ્વરાજ અભિયાન",
"Login023": "સ્ટાર પસંદ કરો",
"Login024": "ભૂમિકા પસંદ કરો",
"Login025": "જિલ્લો પસંદ કરો",
"Login026": "બ્લોક પસંદ કરો",
"Login027": "ગામ પસંદ કરો",

"courseindex009": "વીડિયો સામગ્રી",
"courseindex010": "સ્રોત સામગ્રી",


"forgotpassword001": "તમારો પાસવર્ડ શોધો"
, "forgotpassword002": "ફોન નંબર અથવા ઇમેઇલ આઈડી"
, "forgotpassword003": "ફોન નંબર અથવા ઇમેઇલ આઈડી દાખલ કરો"
, "forgotpassword004": "रजिस्टर्ड फ़ोन नंबर या ईमेल आईडी दर्ज करें"
, "forgotpassword007": "फ़ोन नंबर नहीं मिल रहा है"
, "forgotpassword008": "ईमेल आईडी नहीं मिल रहा है"
, "forgotpassword005": "जानकारी भेजें"
, "forgotpassword006": "लॉगिन पर वापस जाएं"
, "forgotpassword009": "लॉगिन की विवरण रजिस्टर्ड ईमेल आईडी पर भेजा गया है"
, "forgotpassword010": "लॉगिन की विवरण रजिस्टर्ड फ़ोन नंबर पर भेजा गया है"
, "forgotpassword011": "यह एक मान्य फ़ोन नंबर या ईमेल आईडी नहीं है"
, "forgotpassword012": "लॉगिन की विवरण रजिस्टर्ड फ़ोन नंबर और ईमेल आईडी पर भेजा गया है"

, "registration001":"वीडियो ट्यूटोरियल: रजिस्टर कैसे करें"
, "registration002":"वीडियो देखने के लिए पसंदीदा भाषा का चयन करें"
, "registration040":"लिक्यु स्किल्स एलएमएस पर रजिस्टर करें"
, "registration003":"देश"
, "registration004":"ફોન નંબર"
, "registration005":"अपना फ़ोन नंबर डालें"
, "registration011":"कृपया फ़ोन नंबर मान्य है इसकी जाँच करें"
, "registration006":"ईमेल आईडी"
, "registration007":"अपनी ईमेल आईडी दर्ज करें"
, "registration008": "यदि 'भारत' के अलावा 'देश' है तो ईमेल आईडी अनिवार्य है। इसका उपयोग पाठ्यक्रम से संबंधित सभी संचार जैसे लॉगिन, अधिसूचना आदि के लिए किया जाएगा।"
, "registration012":"कृपया ईमेल आईडी मान्य है इसकी जाँच करें"
, "registration009":"આગળ વધો"
, "registration010":"कैसे रजिस्टर करें"
, "registration013":"उपयोगकर्ता नाम पहले से मौजूद है"
, "registration014":"फ़ोन नंबर किसी अन्य उपयोगकर्ता के साथ रजिस्टर्ड है। क्या आप उसी फ़ोन नंबर का उपयोग करके लॉग इन करना चाहते हैं?"
, "registration049":"ईमेल आईडी किसी अन्य उपयोगकर्ता के साथ रजिस्टर्ड है। क्या आप उसी ईमेल आईडी का उपयोग करके लॉग इन करना चाहते हैं?"
, "registration015":"हाँ"
, "registration016":"रद्द करें"
, "registration017":"फ़ोन ओटीपी"
, "registration018":"4 अंकों का ओटीपी नंबर दर्ज करें"
, "registration019":"એસ.એમ.એસ. વાળો ઓટીપી કોડ દાખલ કરો"
, "registration023":"ખોટો ઓટીપી કોડ. કૃપા કરીને એસએમએસ દ્વારા મોકલેલ કોડ દાખલ કરો"
, "registration022":"ईमेल द्वारा भेजा गया ओटीपी कोड दर्ज करें"
, "registration024":"गलत ओटीपी कोड। कृपया ईमेल द्वारा भेजे गए अनुसार दर्ज करें"
, "registration020":"ओटीपी प्राप्त नहीं हुआ। फिर से भेजें?"
, "registration021": "स्टेप 1"
, "registration025":"પૂરું નામ"
, "registration026":"તમારું નામ દાખલ કરો"
,"registration056":"कम से कम 3 अक्षर आवश्यक हैं। केवल विशेष चिह्न जैसे कि - ! ` और अंग्रेजी वर्णमाला की अनुमति है।"
, "registration031":"नाम में कोई संख्या मान नहीं हो सकता"
, "registration027":"न्यूनतम 8 अंकों का पासवर्ड दर्ज करें"
, "registration032":"दोनों पासवर्ड मेल नहीं खाते"
, "registration028":"पुनः पासवर्ड डालें"
, "registration029":"जैसा ऊपर दिया गया है वैसा ही पासवर्ड फिर से डालें"
, "registration030":"पासवर्ड में कम से कम एक अपरकेस, लोअरकेस और नंबर होना चाहिए।"
, "registration033":"राज्य"
, "registration034":"राज्य चुनें"
, "registration035":"शहर"
, "registration036":"शहर का नाम दर्ज करें"
, "registration037":"शिक्षा"
, "registration038":"अपनी उच्चतम योग्यता का चयन करें"
, "registration039":"मैं सहमत हूं, सभी विवरण सटीक हैं"
, "registration041":"लिक्यु स्किल्स एलएमएस में आपका स्वागत है"
, "registration042":"आप लिक्यु स्किल्स एलएमएस पर सफलतापूर्वक रजिस्टर्ड हो गए हैं"
, "registration043":"आपका लॉगिन विवरण नीचे साझा किया गया है"
, "registration044":"हमने एसएमएस के माध्यम से लॉगिन विवरण भी भेजा है"
, "registration050":"हमने ईमेल के माध्यम से लॉगिन विवरण भी भेजा है"
, "registration051":"हमने एसएमएस और ईमेल के माध्यम से लॉगिन विवरण भी भेजा है"
, "registration045":"लॉगिन आईडी है"
, "registration046":"पासवर्ड है"
, "registration047":"नोट: आप लॉगिन करने के लिए अपने फ़ोन नंबर का भी उपयोग कर सकते हैं"
, "registration048":"सीखना शुरू करो"
, "registration054": "ईमेल ओटीपी"
,"coursecontent001":"वीडियो ट्यूटोरियल: कैसे कोर्स सामग्री देखें"
,"mycourse001": "वीडियो ट्यूटोरियल: LinQ Skills LMS का पूर्वाभ्यास"
,"eligibility001": "वीडियो ट्यूटोरियल: टेस्ट का प्रयास कैसे करें"


,"userprofile001": "पासवर्ड बदलें"
,"userprofile002": "संपर्क बदलें"
,"userprofile003": "उपयोगकर्ता की पसंद"
,"userprofile004": "ओटीपी भेजा गया। 10 मिनट तक मान्य रहेगा।"
,"userprofile005": "प्रतिभागी / छात्र"
,"userprofile006": "फ़ोटो हटाएँ"
,"userprofile007": "फोटो अपलोड करें"
,"userprofile008": "बचाएं और सुधार करें"
,"userprofile009": "अपना प्रोफ़ाइल फ़ोटो चुनें और फिर सबमिट करने के लिए फ़ोटो अपलोड करें पर क्लिक करें। छवि प्रारूप की अनुमति: JPG/PNG/GIF। आकार 2MB से अधिक नहीं।"
,"userprofile010": "निजी विवरण"
,"userprofile011": "પ્રોફાઇલ વિગતો"
,"userprofile012": "निर्देश का माध्यम"
,"userprofile013": "LinQ LMS पर पाठ्यक्रम सामग्री देखने के लिए इस विकल्प को मेरी डिफ़ॉल्ट भाषा के रूप में सेट करें"
,"userprofile014": "फ़ाइल चुनें और फिर फ़ाइल अपलोड करें पर क्लिक करें"
,"userprofile015": "छवि गैलरी"
,"userprofile016": "फोटो लो"
,"userprofile017": "फ़ाइल अपलोड करें"
,"userprofile018": "छवि प्रारूप की अनुमति: JPG/PNG/GIF।"
,"userprofile019": "बदलें"
,"userprofile020": "जांच और सुधार करें"
,"userprofile021": "ઓટીપી મોકલો"
,"userprofile022": "प्रोफ़ाइल समायोजन"
,"userprofile023": "ઓટીપી"

,"courseindex001": "છેલ્લો સેશનથી ચાલુ રાખો"
,"courseindex002": "ટોપિક પર જાઓ"
,"courseindex003": "કન્ટેન્ટ"
,"courseindex004": "ઑનલાઇન લેકચર્સ"
,"courseindex005": "સર્ટિફિકેટ"

,"mycourse001": ""
,"mycourse002": "મારા કોર્સ"
,"mycourse003": "ચાલી રહેલી છે"
,"mycourse004": "સ્વાગત છે,"
,"mycourse005": "કોર્સ શરૂ કરો"
,"mycourse006": "કોર્સ ચાલુ રાખો"
,"mycourse007": "સરનામું જુઓ"
,"courseindex005": "સર્ટિફિકેટ"

,"topic001": "પાછલું"
,"topic002": "આગળ"
,"certificate001": "સર્ટિફિકેટ જાહેર કરવામાં આવશે"
,"certificate002": "કોર્સ પૂરો થયા પછી સર્ટિફિકેટ ઉપલબ્ધ હશે"

,"certificate003": "સરતિફિકેટ ડાઉનલોડ કરો"


,"Policy001": "ગોપનીયતા નીતિ અને શરતો"


,"postregform025": "તમારું પૂરૂં નામ દાખલ કરો."
,"postregform026": "આ નામ તમારા પ્રોફાઇલ નામ તરીકે દર્શાવામાં આવશે અને પ્રમાણપત્રમાં પણ ઉપયોગમાં લેવામાં આવશે।"
,"postregform027": "કૃપા કરીને સાચી ઇમેઇલ આઈડી દાખલ કરો, કારણ કે આ અલર્ટ્સ અને મહત્વનીય સંદેશો મોકલવા માટે વપરાશ કરવામાં આવશે."
,"postregform028": "Thanks for your time!"

,"postregform029": "Thanks for your time!"

,"Chatbot001":"હું તમારો અંગત શિક્ષણ સહાયક છું. હું તમને વધુ સારી રીતે શીખવામાં મદદ કરવા માટે મારા શ્રેષ્ઠ પ્રયાસો કરીશ."
,"Chatbot002":"નમસ્તે"
,"Chatbot003":"તમારી ક્વેરીમાં મદદ કરવા માટે અહીં કેટલાક સંસાધનો છે:"
,"Chatbt004":"RGSA ચેટબોક્સ"

}
},

},
//detection: {
// order: ['cookie', 'htmlTag', 'localStorage', , 'path', 'subdomain'],
// caches: ['cookie'],
//},
//backend: {
// loadpath:'/asessts/languages/{{lang}}/translation.json'
//},
react: {
useSuspense: false
}

});
const t = i18n.t;
const val = t(textKey);

useEffect(() => {
if (setvalue != undefined) {
setvalue(val)
}
})
return <>{val}</>;
}

// New function to export val directly
export const getTranslatedValue = (textKey) => {
    const val = i18n.t(textKey);
    return val;
};
