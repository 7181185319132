import { useEffect, useState } from "react";
import { variables } from "../Shared/Variables";

export const shouldRedirectTp = (tpId) => {
    const redirectIds = variables.tpIdsToRedirect || [];
    if(redirectIds.includes(tpId)){
        return true;
    }
    return false;
}

export const redirectUser = (tpId, userId, token, tpBaseURL) => {
    const redirectionBaseUrl = (tpBaseURL || '') + '#/tokenLogin';
    const redirectionFullUrl = createQueryParams(
        {
            token,
            tpId,
            userId
        },
        redirectionBaseUrl
    );
    window.location.href = redirectionFullUrl;
}

export const createQueryParams = (params, url = '') => {
    const queryString = Object.keys(params)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');

    if (url) {
        const [baseUrl, existingQuery] = url.split('?');
        const separator = existingQuery ? '&' : '';
        return `${baseUrl}?${existingQuery || ''}${separator}${queryString}`;
    } else {
        return `?${queryString}`;
    }
}

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

function generateString() {
    let result = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < 16; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}


export const createSession = async (cookies, ipAddress, isMobile) => {
    // console.log("session created")
    try {
        let url = variables.API_URL + 'api/InserUserLoginDetails'
        const sessionToken = generateString();

        /*  const SIGNING_KEY = 'lInQsKiLlS';
        const sessionToken = generateSessionToken(SIGNING_KEY); 
        // console.log(sessionToken,"session")   */

        const data = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({

                userId: localStorage.getItem('login-userId'),
                sessionID: sessionToken,
                ipAddress: ipAddress,
                deviceInfo: isMobile ? "Mobile" : "Desktop"

            })
        })

        const res = await data.json();
        if (res.isSuccess) {
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 1);
            cookies.set('sessionId', sessionToken, { path: '/', expires: expirationDate })
        }
        // console.log(res, "insert details")

    } catch (error) {
        // console.log(error)
    }
}

export const useIpAddress = () => {
    const [ipAddress, setIpAddress] = useState('');
    const [status, setStatus] = useState('loading'); // Initialize status as 'loading'

    useEffect(() => {
        const fetchIpAddress = async () => {
            try {
                const response = await fetch('https://api.ipify.org?format=json');
                const data = await response.json();
                setIpAddress(data.ip);
                setStatus('loaded'); // Update status to 'loaded' on success
            } catch (error) {
                console.log(error);
                setStatus('error'); // Update status to 'error' on failure
            }
        };

        fetchIpAddress();
    }, []);

    return { ipStatus: status, ipAddress };
}

export const logEveryoneOut = async () => {
    //// console.log("logged everyionev out")
    try {
        const userId = localStorage.getItem('login-userId');
        if(!userId){
            return;
        }
        let url = variables.API_URL + 'api/LogEveryoneOut'
        const data = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userId
            })
        })
        const res = await data.json();
        // console.log(res, "logged everyionev out")
    } catch (error) {
        // console.log(error)
    }

}