import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill's styles

const RichTextEditor = (props) => {
  const [text, setText] = useState();
  console.log("props in rich editor text : ", props);
  // Handle text changes
  const handleChange = (value) => {
    console.log("props.currentQuestionIndex", props.currentQuestionIndex);
    console.log("props.quizQuestions", props.quizQuestions);
    if (value.length <= 5000) {
      setText(value);
      const updatedQuestions = [...props.quizQuestions];
      updatedQuestions[props.currentQuestionIndex].SelectedAnswer =value;
      props.setquizQuestions(updatedQuestions);
      props.setUserAns(value);
    } else {
      alert("You have reached the maximum character limit of 5000.");
    }
  };

  useEffect(() => {
    setText(props?.currentQuestion?.SelectedAnswer)
  }, [props.currentQuestion])



  // Quill modules for toolbar options
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  // Quill formats
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
  ];

  console.log("final text : ", text);

  return (
    <div>
      <ReactQuill
        value={text}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        placeholder="Start typing..."
      />
      {/* <p>
        Characters: {text.length} / 5000
      </p> */}
    </div>
  );
};

export default RichTextEditor;