
import React, { useEffect, useState, useRef } from "react";
import { variables } from '../Shared/Variables';
import CryptoJS from "crypto-js";
import axios from "axios";
import { async } from "q";
import TestPopup from "../Popups/TestPopup";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CustumLoader from "../Shared/CustumLoader";

export default function ImageUploadFiles({ setFilesToUpload, setFilesAns, fileId, filesToUpload, queId, testId, userId, IsAssessment, setSkip, queAnsweredCount,removeQuestionId}) {


  const singleFileDetail = filesToUpload.filter((item) => {
    return item.queId == queId
  })

  // // console.log("singleFileDetail :", singleFileDetail)
  const [chooseFrom, setChooseFrom] = useState("");
  const [isPopUpShow, setIsPopUpShow] = useState(false);
  const [btnUploadFiles, setBtnUploadFiles] = useState(true);
  const [text, setText] = useState("");
  var prompt = "<h5>Can't Select More Then 5 Files</h5>";
  var prompt2 = "<h5>Something went wrong please try again</h5>";
  var imagesPrompt = "<h5>Formats allowed: PNG, JPG, JPEG, GIF</h5>";
  var videosPrompt = "<h5>Formats allowed: MP3, MP4</h5>";
  var docPrompt = "<h5>Formats allowed: PDF, CSV, XLSX, XLS, DOC, DOCX, PPT, PPTX, TXT</h5>";
  var zipPrompt = "<h5>Formats allowed: ZIP, RAR, 7z</h5>";
  var sizeLimitPrompt = "<h5>Please select file less then 30 MB</h5>";



  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const [isLoader, setIsLoader] = useState(false);
  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth)
    setWindowHeight(window.innerHeight)
  }
  // useEffect(() => {
  //   window.addEventListener('resize', setWindowDimensions);
  //   return () => {
  //     window.removeEventListener('resize', setWindowDimensions)
  //   }
  // }, [])

  useEffect(() => {
    const questionOnPrev = filesToUpload.filter((que) => {
      return que.queId == queId
    })
    // console.log("questionOn in useEffect questionOnPrev[0].files.length : ",questionOnPrev[0].files.length);
    if (questionOnPrev[0].files.length == 0) {
      setBtnUploadFiles(true);
    } else {
      setBtnUploadFiles(false);
    }
  }, [queId])

  useEffect(() => {
    console.log("files uplaod:", JSON.stringify(filesToUpload));

    if (filesToUpload) {
      // Find the file entry for the current question
      const currentFileData = filesToUpload.find(
        (file) => file.queId === queId
      );

      // Check if a file is uploaded for the current question
      if (currentFileData && currentFileData.uploadedFiles.length > 0) {
        setSkip(false); // Enable "Next" button
      } else {
        setSkip(true); // Disable "Next" button
      }
    }
  }, [filesToUpload]);
  // // console.log("windowWidth : ", windowWidth);






  const chooseFileHandler = (e, queId, fileType) => {
    e.stopPropagation();

    const MAX_FILE_SIZE = 29 * 1024 * 1024; // 50MB in bytes
    var isMaxSize = ""

    const allFiles = Array.prototype.slice.call(e.target.files);
    console.log("allFiles : ", allFiles);

    for (let i = 0; i < allFiles.length; i++) {
      if (allFiles[i].size > MAX_FILE_SIZE) {
        // alert("more then 50 mb");
        setText("7");
        isMaxSize = "true";
      }
    }

    if (isMaxSize == "true") {
      setIsPopUpShow(true);
    } else {
      // Filter files based on type and size
      const rightFormatChoosedFiles = allFiles.filter((item) => {
        let choosedFileTypeArr = item.type.split("/");
        // Check file type
        if (fileType == "image") {
          return (
            choosedFileTypeArr[1] === "png" ||
            choosedFileTypeArr[1] === "gif" ||
            choosedFileTypeArr[1] === "jpeg" ||
            choosedFileTypeArr[1] === "jpg"
          );
        } else if (fileType == "videoAudio") {
          return (
            choosedFileTypeArr[1] === "mpeg" ||
            choosedFileTypeArr[1] === "mp4"
          );
        } else if (fileType == "doc") {
          return (
            choosedFileTypeArr[1] === "plain" ||
            choosedFileTypeArr[1] === "vnd.ms-powerpoint" ||
            choosedFileTypeArr[1] === "msword" ||
            choosedFileTypeArr[1] === "csv" ||
            choosedFileTypeArr[1] === "vnd.ms-excel" ||
            choosedFileTypeArr[1] === "pdf" ||
            choosedFileTypeArr[1] === "vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            choosedFileTypeArr[1] === "vnd.openxmlformats-officedocument.presentationml.presentation"
          );
        } else if (fileType == "zip") {
          return (
            choosedFileTypeArr[1] === "x-zip-compressed" ||
            choosedFileTypeArr[0] === ""
          );
        }
        return false;
      });

      // Check for invalid files
      if (rightFormatChoosedFiles.length < allFiles.length) {
        setText(fileType === "image" ? 3 : fileType === "videoAudio" ? 4 : fileType === "doc" ? 5 : 6);
        setIsPopUpShow(true);
      }

      // Limit to 5 files
      if (rightFormatChoosedFiles.length <= 5) {
        let tempFilesToUpload = [...filesToUpload];
        const result = tempFilesToUpload.map((obj) => {
          if (obj.queId === queId) {
            let newFiles = obj.files;
            if (newFiles.length <= 4 && newFiles.length + rightFormatChoosedFiles.length <= 5) {
              for (let i = 0; i < rightFormatChoosedFiles.length; i++) {
                newFiles.push(rightFormatChoosedFiles[i]);
              }
              if (rightFormatChoosedFiles.length > 0) {
                setBtnUploadFiles(false);
              }
              return { ...obj, files: newFiles, isUploaded: 1 };
            } else {
              setIsPopUpShow(true);
            }
          }
          return obj;
        });
        setFilesToUpload(result);
      } else {
        setIsPopUpShow(true);
      }

      uploadFiles();
    }
    e.target.value = '';
  };


  const removeImage = (item, qId) => {
    // console.log("remove image from array : ", item);
    // console.log("from which que id file is removed : ", qId)
    let tempFilesToUpload = [...filesToUpload]
    // console.log("tempFilesToUpload in remove image : ",tempFilesToUpload);
    if (tempFilesToUpload.length <= 1) {
    }
    const result = tempFilesToUpload.map((obj) => {
      if (obj.queId === queId) {
        // console.log("obj.files.length :: ",obj.files.length);
        if (obj.files.length < 2) {

          setBtnUploadFiles(true);
          return {
            ...obj, files: obj.files.filter((it) => {
              return it.name !== item.name && it.size !== item.size
            }), isUploaded: 0
          };
        } else {
          return {
            ...obj, files: obj.files.filter((it) => {
              return it.name !== item.name && it.size !== item.size
            })
          };
        }

      }
      return obj
    })
    // console.log("result in choose file : ", result)
    setFilesToUpload(result);
    // const tempArr = filesToUpload;
    // const imagesAfterAlter = tempArr.filter((it) => {
    //   return it.name !== item.name
    // })
    // // console.log("imagesAfterAlter : ", imagesAfterAlter);

  }

  const uploadFiles = async () => {

    try {


      setBtnUploadFiles(true);
      setIsLoader(true);
      // console.log(`uploadFiles called : ${testId} : ${userId} ${queId}`)
      let formData = new FormData();
      let files;
      // filesToUpload is a [{},{}] in which storing files and there values in obj
      for (let i = 0; i < filesToUpload.length; i++) {
        // console.log(`i = ${i} filesToUpload : ${filesToUpload[i].queId}`);
        if (filesToUpload[i].queId == queId) {
          // console.log(`setting new files for que = ${filesToUpload[i].queId}`);
          files = filesToUpload[i].files;
        }
      }


      // console.log("newFiles after : ", files);
      files.map((it, ind) => {
        formData.append('files', files[ind]);
      })
      formData.append('userId', userId);
      formData.append('TestId', testId);
      formData.append('QuestionId', queId);
      formData.append('AttemptNo', 1);
      formData.append('IsAssessment', IsAssessment)
      const res = await axios.post(variables.API_URL + "api/UplaodEGAFiles", formData);

      if (res.data.isSuccess) {
        // console.log("files uploaded successfully");
        if (res.data.nameList.length == files.length) {
          const result = filesToUpload.map((obj) => {
            if (obj.queId === queId) {

              let newUploadedFiles = obj.uploadedFiles;
              for (let i = 0; i < res.data.nameList.length; i++) {
                // console.log(` res.data.nameList[i] : ${res.data.nameList[i]}`);
                newUploadedFiles.push(res.data.nameList[i])

              }
              // console.log("newUploadedFiles after : ", newUploadedFiles);
              let fileAnsStr = newUploadedFiles.join('~');
              // console.log("fileAnsStr : ", fileAnsStr)

              // setFilesAns(fileAnsStr);
              return { ...obj, isUploaded: 2, files: [], uploadedFiles: newUploadedFiles, ansStr: fileAnsStr };
            }
            return obj
          })
          // console.log("result after success : ", result);
          setFilesToUpload(result);
          setSkip(false);
          setIsLoader(false);
          // setBtnUploadFiles(false);
        }
      } else {
        setIsLoader(false);
        setBtnUploadFiles(false);
        setSkip(true)
        // console.log("something went wrong")
      }
    } catch (error) {
      // console.log("error occured in uploading files : ", error);
      setText(2)
      setIsPopUpShow(true)
      setBtnUploadFiles(false)
      setIsLoader(false);
    }
  }

  const softDel = (fileName, queId) => {
    console.log("softDel : ", fileName, queId);
    let tempFilesToUpload = [...filesToUpload]
    const result = tempFilesToUpload.map((obj) => {
      if (obj.queId === queId) {
        if (obj.uploadedFiles.length >= 1) {
          // console.log("do solft del here");
          let alteredUploadedFiles = obj.uploadedFiles.filter((it) => {
            return it !== fileName;
          })
          // console.log("alteredUploadedFiles : ", alteredUploadedFiles)
          let fileAnsStr = alteredUploadedFiles.join('~');
          // console.log("fileAnsStr after soft del : ", fileAnsStr)
          return { ...obj, uploadedFiles: alteredUploadedFiles, ansStr: fileAnsStr };
        } else {
          // console.log("no files uploaded for this que");
          return obj
        }
      }
      return obj
    })
    // console.log("result in choose file : ", result)
    console.log("queAnsweredCount : ".queAnsweredCount);
    removeQuestionId(queId)
    setFilesToUpload(result);
    // Check if all files for the current question are deleted
    const currentQuestionFiles = result.find((obj) => obj.queId === queId);
    if (currentQuestionFiles && currentQuestionFiles.uploadedFiles.length === 0) {
      console.log("calling skip")
      setSkip(true); // Set skip to true if no files are uploaded
    }


  }
  // // console.log("btnUploadFiles :", btnUploadFiles)
  // // console.log("filesToUpload : ", filesToUpload);
  return (
    <>
      {isPopUpShow &&
        <TestPopup show={true} txt={text == "2" ? prompt2 : text == "3" ? imagesPrompt : text == "4" ? videosPrompt : text == "5" ? docPrompt : text == "6" ? zipPrompt : text == "7" ? sizeLimitPrompt : prompt} title="Warning" onOk={(e) => setIsPopUpShow(false)} onClose={(e) => setIsPopUpShow(false)} type="prompt" />}
      {
        singleFileDetail[0].queId == queId && singleFileDetail[0]?.uploadedFiles && singleFileDetail[0]?.uploadedFiles?.length > 0 ?
          <>
            <div class="upload-files">
              <div class="col-lg-9">
                {
                  singleFileDetail[0].uploadedFiles.map((item, index) => {
                    // // console.log("item in uploaded files : ", item)
                    const splitedItem = item.split(".");
                    // // console.log("splitedItem : ", splitedItem);
                    let extensionName = splitedItem[splitedItem.length - 1];
                    let truncFileName = splitedItem[0]
                    // // console.log("truncFileName : ", truncFileName, extensionName);
                    return (
                      <>



                        <div key={index} class="d-flex col-lg-12 pb-3">
                          <div class="d-flex col-lg-8">
                            <img src="../../Assets/Linqskills_Icons/sessionstatus-submitted.svg" class="pe-2"></img>
                            <img src="../../Assets/Linqskills_Icons/upload-img.svg" class="pe-2"></img>

                            {/* <h6 class="mt-2">{`${truncFileName}.${extensionName}`}</h6> */}
                            <div class="mt-2 d-flex">
                              <h6 class="line-clamp-one">{truncFileName}</h6>
                              <h6>.{extensionName}</h6>
                            </div>


                          </div>

                          <div class="col-lg-4 d-flex float-end properties">
                            <img src="../../Assets/Linqskills_Icons/close-small.svg" onClick={() => softDel(item, singleFileDetail[0].queId)} class="cursor-pointer"></img>
                          </div>
                        </div>

                      </>
                    )
                  })
                }
              </div>
            </div>
          </>
          : ""
      }
      {/*  <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoader}

      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      {isLoader && <CustumLoader message="Loading..." />}

      {fileId == 4 ?
        <>
          <div className="upload-files">
            <div class="col-md-12">
              <div class="col-lg-5 col-sm-12 pt-1 pb-3 text-center d-none d-md-block">
                <img src="../../Assets/Linqskills_Icons/upload-default.svg" className="w-auto"></img>
              </div>
              <div class="col-lg-5 col-sm-12 text-center">
                <h5 class="mb-2">Formats allowed: PNG, JPG, JPEG, GIF</h5>
                <h5>Upload file of maxium 30MB</h5>
                <div class="row pb-2 pt-2 text-center">
                  <h6>Choose a file and then click on “Upload Files” to submit for evaluation  </h6>
                </div>
              </div>
              <div class="d-flex">
                <div class="file-input-wrapper col-md-3 "><input type="file" accept="image/png, image/gif, image/jpeg, image/jpg" multiple onChange={(e) => chooseFileHandler(e, queId, "image")} id="file-input" class="hidden" /> <label for="file-input" class="secondary-btn cursor-pointer btn-change me-3">Upload Files</label> </div>
              </div>
              <div class="col-lg-9 col-sm-12 pt-4 pb-4">

                {singleFileDetail[0].isUploaded == 1 && singleFileDetail[0].queId == queId ?
                  <>
                    {singleFileDetail[0].files.map((item, index) => {
                      // // console.log("item.size in kb : ", item)
                      const splitedItem = item.name.split(".");
                      // // console.log("splitedItem : ", splitedItem);
                      let extensionName = splitedItem[splitedItem.length - 1];
                      let truncFileName = splitedItem[0]
                      // // console.log("truncFileName : ", truncFileName, extensionName);
                      return (
                        <>
                          <div key={index} class="d-flex col-lg-12 pb-3">
                            <div class="d-flex col-lg-8">
                              <img src="../../Assets/Linqskills_Icons/sessionstatus-completed.svg" class="pe-2"></img>
                              <img src="../../Assets/Linqskills_Icons/upload-img.svg" class="pe-2"></img>
                              <div class="mt-2 d-flex">
                                <h6 class="line-clamp-one">{truncFileName}</h6>
                                <h6>.{extensionName}</h6>
                              </div>

                              {/* <h6 class="mt-2">{`${truncFileName}.${extensionName}`}</h6> */}

                            </div>

                            <div class="col-lg-4 d-flex float-end properties">
                              <h6 class="mt-2 ">{item.size / 1000 < 1000 ? `${(item.size / 1000).toFixed(1)} KB` : `${(item.size / 1000000).toFixed(1)} MB`} </h6>
                              <img src="../../Assets/Linqskills_Icons/close-small.svg" onClick={() => removeImage(item, singleFileDetail[0].queId)} class="cursor-pointer"></img>

                            </div>
                          </div>
                        </>
                      )
                    })
                    }
                  </> :
                  ""
                }
              </div>
              <style>{
                `
          .hidden { display: none; } .file-input-wrapper { position: relative; display: inline-block; } .custom-button { /* Add your preferred button styles here */ padding: 10px 15px; background-color: #007bff; color: #fff; cursor: pointer; } 
          `
              }</style>
            </div>
          </div>
        </> :
        fileId == 3 ?
          <>
            <div className="upload-files">
              <div class="col-md-12">
                {/* <input type="file" accept="image/*" onChange={chooseFileHandler} style={{visibility:"hidden"}} class="col-lg-4 col-sm-12 pt-4 pb-4">
        </input>
          <label class="secondary-btn w-100">Choose Image
          </label> */}
                <div class="col-lg-5 col-sm-12 pt-1 pb-3 text-center d-none d-md-block">
                  <img src="../../Assets/Linqskills_Icons/upload-default.svg" className="w-auto"></img>
                </div>
                <div class="col-lg-5 col-sm-12 text-center">
                  <h5 class="mb-2">Formats allowed: ZIP, RAR, 7z</h5>
                  <h5>Upload file of maxium 30MB</h5>
                  <div class="row pb-2 pt-2 text-center">
                    <h6>Choose a file and then click on “Upload Files” to submit for evaluation  </h6>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="file-input-wrapper col-md-3"><input type="file" accept=".zip,.rar,.7zip" multiple onChange={(e) => chooseFileHandler(e, queId, "zip")} id="file-input" class="hidden" /> <label for="file-input" class="secondary-btn btn-change me-3">Upload Files</label> </div>
                </div>
                <div class="col-lg-9 col-sm-12 pt-4 pb-4">

                  {singleFileDetail[0].isUploaded == 1 && singleFileDetail[0].queId == queId ?
                    <>
                      {singleFileDetail[0].files.map((item, index) => {
                        // console.log("item.size in kb : ", item)
                        const splitedItem = item.name.split(".");
                        // console.log("splitedItem : ", splitedItem);
                        let extensionName = splitedItem[splitedItem.length - 1];
                        let truncFileName = splitedItem[0]
                        // console.log("truncFileName : ", truncFileName, extensionName);
                        return (
                          <>
                            <div key={index} class="d-flex col-lg-12 pb-3">
                              <div class="d-flex col-lg-8">
                                <img src="../../Assets/Linqskills_Icons/sessionstatus-completed.svg" class="pe-2"></img>
                                <img src="../../Assets/Linqskills_Icons/upload-img.svg" class="pe-2"></img>
                                <div class="mt-2 d-flex">
                                  <h6 class="line-clamp-one">{truncFileName}</h6>
                                  <h6>.{extensionName}</h6>
                                </div>

                                {/* <h6 class="mt-2">{`${truncFileName}.${extensionName}`}</h6> */}

                              </div>

                              <div class="col-lg-4 d-flex float-end properties">
                                <h6 class="mt-2">{item.size / 1000 < 1000 ? `${(item.size / 1000).toFixed(1)} KB` : `${(item.size / 1000000).toFixed(1)} MB`} </h6>
                                <img src="../../Assets/Linqskills_Icons/close-small.svg" onClick={() => removeImage(item, singleFileDetail[0].queId)} class="cursor-pointer"></img>

                              </div>
                            </div>
                          </>
                        )
                      })
                      }
                    </> :
                    ""
                  }
                  {/* <div class="col-lg-7 col-sm-12 pb-4">
                    {btnUploadFiles ?
                      <button onClick={uploadFiles} disabled={btnUploadFiles} class="disabled-btn w-100">
                        Upload Files</button> :
                      <button onClick={uploadFiles} disabled={btnUploadFiles} class="primary-btn w-100">
                        <img src="../../Assets/Linqskills_Icons/uploadfile-btn.svg" class=""></img>

                        Upload Files</button>
                    }
                  </div> */}
                </div>
                <style>{
                  `
          .hidden { display: none; } .file-input-wrapper { position: relative; display: inline-block; } .custom-button { /* Add your preferred button styles here */ padding: 10px 15px; background-color: #007bff; color: #fff; cursor: pointer; } 
          `
                }</style>
              </div>
            </div>
          </> :
          fileId == 2 ?
            <>
              <div className="upload-files">
                {/* <div class="d-flex">
                  <div class="options pt-0">
                    <div class="form-check">
                      <label class="form-check-label" for="flexRadioDefault1">
                        <input class="form-check-input" checked="checked" type="radio" name="flexRadioDefault" id="flexRadioDefault1"></input>
                        Video/Audio Gallery
                      </label>
                    </div>
                  </div>
                  <div class="options pt-0">
                    <div class="form-check">
                      <label class="form-check-label" for="flexRadioDefault1">
                        <input class="form-check-input" type="radio" name="" id="flexRadioDefault1"></input>
                        Take Video
                      </label>
                    </div>
                  </div>
                </div> */}

                <div class="col-md-12">
                  <div class="col-lg-5 col-sm-12 pt-1 pb-3 text-center d-none d-md-block">
                    <img src="../../Assets/Linqskills_Icons/upload-default.svg" className="w-auto"></img>
                  </div>
                  <div class="col-lg-5 col-sm-12 text-center">
                    <h5 class="mb-2">Formats allowed: MP3, MP4</h5>
                    <h5>Upload file of maxium 30MB</h5>
                    <div class="row pb-2 pt-2 text-center">
                      <h6>Choose a file and then click on “Upload Files” to submit for evaluation  </h6>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="file-input-wrapper col-md-3"><input type="file" accept="audio/mp3,video/mp4" multiple onChange={(e) => chooseFileHandler(e, queId, "videoAudio")} id="file-input" class="hidden" /> <label for="file-input" class="cursor-pointer secondary-btn btn-change me-3">Upload Files</label> </div>
                  </div>
                  <div class="col-lg-9 col-sm-12 pt-4 pb-4">

                    {singleFileDetail[0].isUploaded == 1 && singleFileDetail[0].queId == queId ?
                      <>
                        {singleFileDetail[0].files.map((item, index) => {
                          // // console.log("item.size in kb : ", item)
                          const splitedItem = item.name.split(".");
                          // // console.log("splitedItem : ", splitedItem);
                          let extensionName = splitedItem[splitedItem.length - 1];
                          let truncFileName = splitedItem[0]
                          // // console.log("truncFileName : ", truncFileName, extensionName);
                          return (
                            <>
                              <div key={index} class="d-flex col-lg-12 pb-3">
                                <div class="d-flex col-lg-8">
                                  <img src="../../Assets/Linqskills_Icons/sessionstatus-completed.svg" class="pe-2"></img>
                                  <img src="../../Assets/Linqskills_Icons/upload-img.svg" class="pe-2"></img>
                                  <div class="mt-2 d-flex">
                                    <h6 class="line-clamp-one">{truncFileName}</h6>
                                    <h6>.{extensionName}</h6>
                                  </div>

                                  {/* <h6 class="mt-2">{`${truncFileName}.${extensionName}`}</h6> */}

                                </div>

                                <div class="col-lg-4 d-flex float-end properties">
                                  <h6 class="mt-2">{item.size / 1000 < 1000 ? `${(item.size / 1000).toFixed(1)} KB` : `${(item.size / 1000000).toFixed(1)} MB`} </h6>
                                  <img src="../../Assets/Linqskills_Icons/close-small.svg" onClick={() => removeImage(item, singleFileDetail[0].queId)} class="cursor-pointer"></img>

                                </div>
                              </div>
                            </>
                          )
                        })
                        }
                      </> :
                      ""
                    }
                    {/* <div class="col-lg-7 col-sm-12 pb-4">
                      {btnUploadFiles ?
                        <button onClick={uploadFiles} disabled={btnUploadFiles} class="cursor-pointer disabled-btn w-100">
                          Upload Files</button> :
                        <button onClick={uploadFiles} disabled={btnUploadFiles} class="primary-btn w-100">
                          <img src="../../Assets/Linqskills_Icons/uploadfile-btn.svg" class="cursor-pointer"></img>
                          Upload Files</button>
                      }
                    </div> */}
                    {/* <img src="../../Assets/Linqskills_Icons/uploadfile-btn.svg" class=""></img> */}
                  </div>
                  <style>{
                    `
          .hidden { display: none; } .file-input-wrapper { position: relative; display: inline-block; } .custom-button { /* Add your preferred button styles here */ padding: 10px 15px; background-color: #007bff; color: #fff; cursor: pointer; } 
          `
                  }</style>
                </div>
              </div>
            </> :
            <>
              <div className="upload-files">
                <div class="col-md-12">
                  <div class="col-lg-5 col-sm-12 pt-1 pb-3 text-center d-none d-md-block">
                    <img src="../../Assets/Linqskills_Icons/upload-default.svg" className="w-auto"></img>
                  </div>
                  <div class="col-lg-5 col-sm-12 text-center">
                    <h5 class="mb-2">Formats allowed: PDF, CSV, XLSX, XLS, DOC, DOCX, PPT, PPTX, TXT</h5>
                    <h5>Upload file of maxium 30MB</h5>
                    <div class="row pb-2 pt-2 text-center">
                      <h6>Choose a file and then click on “Upload Files” to submit for evaluation  </h6>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="file-input-wrapper col-md-3 "><input type="file" accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,text/plain,.pptx" multiple onChange={(e) => chooseFileHandler(e, queId, "doc")} id="file-input" class="hidden" /> <label for="file-input" class="secondary-btn btn-change me-3 cursor-pointer">Upload Files</label> </div>
                  </div>
                  <div class="col-lg-9 col-sm-12 pt-4 pb-4">

                    {singleFileDetail[0].isUploaded == 1 && singleFileDetail[0].queId == queId ?
                      <>
                        {singleFileDetail[0].files.map((item, index) => {
                          // // console.log("item.size in kb : ", item)
                          const splitedItem = item.name.split(".");
                          // // console.log("splitedItem : ", splitedItem);
                          let extensionName = splitedItem[splitedItem.length - 1];
                          let truncFileName = splitedItem[0]
                          // // console.log("truncFileName : ", truncFileName, extensionName);
                          return (
                            <>
                              <div key={index} class="d-flex col-lg-12 pb-3">
                                <div class="d-flex col-lg-8">
                                  <img src="../../Assets/Linqskills_Icons/sessionstatus-completed.svg" class="pe-2"></img>
                                  <img src="../../Assets/Linqskills_Icons/upload-img.svg" class="pe-2"></img>
                                  <div class="mt-2 d-flex">
                                    <h6 class="line-clamp-one">{truncFileName}</h6>
                                    <h6>.{extensionName}</h6>
                                  </div>

                                  {/* <h6 class="mt-2">{`${truncFileName}.${extensionName}`}</h6> */}

                                </div>

                                <div class="col-lg-4 d-flex float-end properties">
                                  <h6 class="mt-2">{item.size / 1000 < 1000 ? `${(item.size / 1000).toFixed(1)} KB` : `${(item.size / 1000000).toFixed(1)} MB`} </h6>
                                  <img src="../../Assets/Linqskills_Icons/close-small.svg" onClick={() => removeImage(item, singleFileDetail[0].queId)} class="cursor-pointer"></img>

                                </div>
                              </div>
                            </>
                          )
                        })
                        }
                      </> :
                      ""
                    }
                    {/* <div class="col-lg-7 col-sm-12 pb-4">
                      {btnUploadFiles ?
                        <button onClick={uploadFiles} disabled={btnUploadFiles} class="disabled-btn w-100">
                          Upload Files</button> :
                        <button onClick={uploadFiles} disabled={btnUploadFiles} class="primary-btn w-100">
                          <img src="../../Assets/Linqskills_Icons/uploadfile-btn.svg" class=""></img>
                          Upload Files</button>
                      }
                    </div> */}
                    {/* <img src="../../Assets/Linqskills_Icons/uploadfile-btn.svg" class=""></img> */}
                  </div>
                  <style>{
                    `
          .hidden { display: none; } .file-input-wrapper { position: relative; display: inline-block; } .custom-button { /* Add your preferred button styles here */ padding: 10px 15px; background-color: #007bff; color: #fff; cursor: pointer; } 
          `
                  }</style>
                </div>
              </div>
            </>
      }
    </>
  )
}

