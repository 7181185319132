import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { variables } from "../Shared/Variables";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import Breadcrumb from "../CourseIndex/Breadcrumb";
import { Bar, Radar } from "react-chartjs-2";
import { confirmAlert } from "react-confirm-alert"; // Import
import { useDispatch } from "react-redux";
import moment from "moment";


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  plugins,
  scales,
  Ticks,
} from "chart.js/auto";
import {
  updateChild,
  updateParent,
} from "../../features/breadcrumb/breadcrumbSlice";
import CompletedTest from "./CompletedTest";
import CustumLoader from "./CustumLoader";
import TestPopup from "../Popups/AlertPopup";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler
);

export default function SummaryScore() {
  const [isLoader, setIsLoader] = useState(false);
  const [showMainDiv, setShowMainDiv] = useState(true);
  const [visibleQuestion, setVisibleQuestion] = useState(null);
  const [summaryScore, setSummaryScore] = useState([]);
  const [aptitudeSkills, setAptitude_skills] = useState([]);
  const [isScoreVisible, setIsScoreVisible] = useState(false);
  const [behavioralSkills, setBehavioralSkills] = useState([]);
  const [communicationSkills, setCommunicationSkills] = useState([]);
  const [technicalSkills, setTechnicalSkills] = useState([]);
  const [writtenSkills, setWrittenSkills] = useState([]);
  const [scores, setScores] = useState([]);
  const [bifurcationScores, setBifurcationScores] = useState([]);
  const [error, setError] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState("prompt");
  const [popupTxt, setPopupTxt] = useState("");
  const [onOkFunction, setOnOkFunction] = useState(null);
  const [onCloseFunction, setOnCloseFunction] = useState(null);
  const [okLabel, setOkLabel] = useState("Ok");

  const toggleQuestionVisibility = (questionIndex) => {
    setVisibleQuestion(
      visibleQuestion === questionIndex ? null : questionIndex
    );
  };

  // Extracting data for chart
  const labels = scores.map((score) => score.parameterIndex);
  const parameterScores = scores.map((score) => score.parameterScore);
  const minParameterScores = scores.map((score) => score.minParameterScore);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Your Score",
        data: parameterScores,
        borderColor: "rgb(153, 102, 255)",
        backgroundColor: "rgb(54, 162, 235)",
        type: "bar",
        order: 1,
      },
      {
        label: "Score to achieve",
        data: minParameterScores,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgb(201, 203, 207)",
        type: "line",
        order: 0,
      },
    ],
  };

  const config = {
    type: "bar",
    data: data,
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: false,
          text: "Chart.js Combined Line/Bar Chart",
        },
      },
    },
    scales: {
      y: {
        grace: "5%",
      },
    },
  };

  const aptitude_skills_options = {
    responsive: true,
    scales: {
      r: {
        // Adjust the radial scale (r is for radar chart)
        min: 0,
        max: 180,
        ticks: {
          stepSize: 36, // Defines the spacing between each scale point
          /* count: 6,  */// Defines the number of scale points
          beginAtZero: true,
        },
        pointLabels: {
          font: {
            weight: "bold",
            size: 12,
          },
        },
      },
    },
    elements: {
      line: {
        borderWidth: 3,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const communication_skills_options = {
    responsive: true,
    scales: {
      r: {
        // Adjust the radial scale (r is for radar chart)
        min: 0,
        max: 10,
        ticks: {
          stepSize: 2, // Defines the spacing between each scale point
          /* count: 6,  */// Defines the number of scale points
          beginAtZero: true,
        },
        pointLabels: {
          font: {
            weight: "bold",
            size: 12,
          },
        },
      },
    },
    elements: {
      line: {
        borderWidth: 3,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  // Grouping function
  const groupByParameterName = (bifurcations) => {
    return bifurcations.reduce((acc, item) => {
      if (!acc[item.parameterName]) {
        acc[item.parameterName] = [];
      }
      acc[item.parameterName].push({
        ...item,
        index: String.fromCharCode(65 + acc[item.parameterName].length), // 'A' starts from 65 in char code
      });
      return acc;
    }, {});
  };
  const getParameterScore = (parameterName, parameters) => {
    const parameter = parameters.find((p) => p.parameterName === parameterName);
    return parameter ? parameter.parameterScore : null;
  };

  const handlePopupOk = () => {
    if (onOkFunction) onOkFunction(); // Execute the dynamic onOk function
    setShowPopup(false); // Hide popup after action
  };

  const handlePopupClose = () => {
    if (onCloseFunction) onCloseFunction(); // Execute the dynamic onClose function
    setShowPopup(false); // Hide popup after action
  };

  const coursedata = JSON.parse(localStorage.getItem("coursedata"));

  const getEgaResult = async () => {
    try {
      setIsLoader(true);
      // console.log("calling getEgaResult : ");
      const AUTH_TOKEN = await localStorage.getItem("accessToken");
      const assesmentResultId = await localStorage.getItem(
        "assesmentResultId"
      );

      const response = await axios.get(
        variables.API_URL +
        `api/GetCandidateExternalScore?assessmentResultID=${assesmentResultId}`,
        {
          headers: {
            Authorization: AUTH_TOKEN,
          },
        }
      );
      if (response.data) {
        setSummaryScore(response.data);
        const extractedScores = response.data.assessmentParameters
          .sort((a, b) => a.displayName.localeCompare(b.displayName))
          .map((param, index) => ({
            displayName: param.displayName,
            parameterScore: param.parameterScore,
            minParameterScore: param.minParameterScore,
            parameterName: param.parameterName,
            parameterIndex: String.fromCharCode(65 + index),
          }))
        setScores(extractedScores);

        const groupedData = groupByParameterName(
          response.data.assessmentScoreBifurcations
        );
        console.log(groupedData);
        setBifurcationScores(groupedData);
        //console.log({extractedScores})
        setIsScoreVisible(true);
        //console.log("response: " + JSON.stringify(response.data))
        // console.log("externalResultDataExists value : ",coursedata.externalResultDataExists)
        if (coursedata.externalResultDataExists == false) {
          const saveResultToLms = await axios.post(
            variables.API_URL + "api/SaveExternalScore",
            response.data
          );
          console.log("saveResultToLms : ", saveResultToLms);
        }
      } else {
        //alert("No assessment result data found.");
        setError(true);
        setPopupTxt("Something went wrong, try again")
        setOnOkFunction(() => () => getEgaResult()); // Set dynamic function
        setShowPopup(true);
        setPopupType("prompt")
        setOkLabel("Try Again")
        /*   confirmAlert({
            message: "Something went wrong, try again",
            buttons: [
              {
                label: "Try Again",
                onClick: () => {
                  getEgaResult();
                },
                class: "primary-btn",
              },
            ],
            closeOnClickOutside: false,
          }); */
      }
    } catch (error) {
      console.error("Error fetching assessment result:", error);
      //alert("An error occurred while fetching the assessment result.");
      setError(true);
    } finally {
      setIsLoader(false);
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("coursedata"));
    getEgaResult();

    dispatch(updateChild(data.courseName));
    dispatch(updateParent("My courses"));
  }, []);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("coursedata"));
    // console.log({ data });
    if (localStorage.getItem("assesmentStatus") === "Completed") {
      const resultbody = {
        userID: localStorage.getItem("login-userId"),
        courseID: data.courseId,
        projectID: data.projectId,
        batchID: data.batchId,
        status: "Completed",
      };
      axios
        .post(
          variables.API_URL + "api/SaveExternalAssessmentStatus",
          resultbody
        )
        .then((res) => {
          // console.log("response from api SaveExternalAssessmentlink : ", res);
          this.setState({ isLoader: false });
          //window.open(newAppUrl, '_blank');
        })
        .catch((err) => {
          // console.log("error from api SaveExternalAssessmentlink : ", err);
        });
    }
  }, []);

  const isScoreGenerated = coursedata?.releaseScoreToCandidate;
  // console.log("isScoreGenerated : ", isScoreGenerated);
  const markedCompleted = coursedata?.markedCompleted;
  // console.log("markedCompleted : ", markedCompleted);

  // console.log("summaryScore : ",summaryScore)
  if (
    !isScoreGenerated ||
    isScoreGenerated == undefined ||
    markedCompleted == "0"
  ) {
    return (
      <div class="container-fluid body-wrapper bg-default">
        <div class="container p-0">
          <Breadcrumb
            redirectLink="mycourses"
            hamburgerClassSow={showMainDiv}
            isScorePage={true}

          />
        </div>
        <div class="container">
          <div class={`course-indexcontent `}>
            <div
              className={`col-lg-9 col-sm-12 col-xs-12 mb-3 content-cards ${"sas"}`}
            >
              {" "}
              <div className="primary-radius bg-white primary-radius mb-3">
                {" "}
                <div className="session-heading bg-body border-bottom">
                  <div className="d-flex">
                    <div class="pink-box">
                      <img
                        src="../../Assets/Linqskills_Icons/assessment-multicolorRed.png"
                        className=""
                      ></img>
                    </div>
                    <div class="heading-one">
                      <h5 className="mb-0 text-truncate">Assessment Score</h5>
                    </div>
                  </div>
                </div>
                <CompletedTest
                  dontShowNavBtns={true}
                  ImgSrc="../../Assets/Linqskills_Icons/test-submited.svg"
                  Message="Your test score will be generated and shared with your college."
                  SecondMessage="To find out your score, please contact your college administrator"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        {showPopup && (
          <TestPopup
            show={showPopup}
            onHide={handlePopupClose}
            onOk={handlePopupOk}
            onClose={handlePopupClose}
            title={popupType == 'prompt' ? "Warning" : "Success"}
            txt={popupTxt}
            type={popupType}
            okLabel={okLabel}
          />
        )}
        {isScoreGenerated && (
          <>
            {/*  <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoader}
            >
              <CircularProgress color="inherit" />
            </Backdrop> */}
            {isLoader && <CustumLoader message="Loading..." />}

            <div class="container">
              <Breadcrumb
                redirectLink="mycourses"
                hamburgerClassSow={showMainDiv}
                isScorePage={true}

              />
            </div>
            {/* shortlisted banner */}
            <div class="ps-4 pe-4">
              <div class="shortlisted-banner justify-space-between col-sm-12 col-xs-12 col-lg-9">
                <div>
                  <img
                    src="../../Assets/Linqskills_Icons/shortlisted-trophy.svg"
                    className="w-100"
                  ></img>
                </div>
                <div>
                  <h4>You have been shortlisted for the next round of the placement process.</h4>
                </div>
                <div>
                  <img
                    src="../../Assets/Linqskills_Icons/shortlisted-triangles.svg"
                    className="w-100"
                  ></img>
                </div>
              </div>
            </div>

            {isScoreVisible ? (
              <>
                {summaryScore?.assessmentParameters[0].acceptanceStatus === "Shortlisted" && <div className="p-4 pt-0 pb-0 col-lg-9 summary-score m-auto col-sm-12 col-xs-12 content-cards ">

                  <div className="profile-heading primary-radius">
                    <div className=" session-heading bg-body border-bottom">
                      <div className="d-flex">
                        <img
                          src="../../Assets/Linqskills_Icons/Acceptance-img.svg"
                          className=""
                        ></img>
                        <h5 className="mb-0 mt-12p ms-2 ps-1  ">
                          {" "}
                          Acceptance Status
                        </h5>
                      </div>
                    </div>
                    <CompletedTest
                      dontShowNavBtns={true}
                      ImgSrc="../../Assets/Linqskills_Icons/Acceptance-icon.svg"
                      Message=" You have been shortlisted for the next round of the placement process."
                    />

                  </div>
                </div>}

                <div className="p-4 pt-0 pb-2 col-lg-9 summary-score m-auto col-sm-12 col-xs-12 content-cards ">
                  <div className="profile-heading primary-radius">
                    <div className=" session-heading bg-body border-bottom">
                      <div className="d-flex">
                        <img
                          src="../../Assets/Linqskills_Icons/assessment_topic_icon.svg"
                          className=""
                        ></img>
                        <h5 className="mb-0 mt-12p ms-2 ps-1 ">
                          {" "}
                          Assessment Score
                        </h5>
                      </div>
                    </div>
                    {/* 2nd box */}
                    <div class="assessment-imgbox p-3 pb-4">
                      <div class="left-img">
                        <img
                          src="../../Assets/Linqskills_Icons/score-anaylsis.svg"
                          className=""
                        ></img>
                      </div>
                      <div class="test-sumitted-box">
                        <h5 class="display-mobile">The test was submitted on
                          <br></br> <span class="bold-700 ">{moment(coursedata?.egaCompletionDate).format("DD MMM YYYY")}</span></h5>
                        <h5 class="display-desktop">The test was submitted on  <span class="bold-700 ">{moment(coursedata?.egaCompletionDate).format("DD MMM YYYY")}</span>
                        </h5>

                        <div class="d-flex justify-content-center mt-4">
                          <div class="text-center">
                            <h5>Assessment Score</h5>
                            <h5 class="score-bold">{summaryScore?.assessmentParameters[0].overallScore} of 100</h5>
                          </div>
                          <div class="ms-3 text-center">
                            <h5>Your Rank</h5>
                            <h5 class="score-bold">{summaryScore?.assessmentParameters[0].ranking}</h5>
                          </div>

                        </div>

                      </div>

                    </div>

                    <div class="col-md-12 col-sm-12 profile-details m-auto mt-4 mb-3">
                      {/* <div class="col-md-11 m-auto ps-3">
                        <span class="score-txt">
                          {" "}
                          You have completed the test, the overall score
                          achieved is{" "}
                          <span class="score-bold">
                            {summaryScore?.assessmentParameters[0].overallScore}
                          </span>
                        </span>
                      </div> */}
                      <div class="mt-3 graph-position col-12 m-auto p-3">
                        <div class="col-lg-8 col-sm-12 ">
                          <Bar data={data} options={config} />
                        </div>

                        <div class="graph-legends col-lg-4 col-sm-12 ">


                          <div class="Child">
                            {scores.map((item) => {
                              return (
                                <div class="d-flex mb-3 legends-parameters">
                                  <div class="d-flex align-items-baseline">
                                    <h5 class="color-grey">{item.parameterIndex}</h5>
                                    <h5 class="ms-2" >{item.parameterScore}</h5>

                                  </div>
                                  <div class="ms-2">
                                    <h5>{item.displayName}</h5>
                                  </div>
                                </div>
                              );
                            })}
                          </div>

                        </div>
                      </div>
                      {/* <div class="col-md-3 m-auto col-sm-12 graph-legends p-3 pb-0"> */}

                      {scores.map((category, index) => {
                        const skillScores =
                          bifurcationScores[category.parameterName];
                        const parameterScore = getParameterScore(
                          category.parameterName,
                          summaryScore?.assessmentParameters
                        );

                        const category_skills = skillScores.map(
                          (item) => item.index
                        );
                        const category_scores = category.parameterName === "aptitude_skills" ? skillScores.map(item => item.testScore < 0 ? 0 : item.testScore)
                          : skillScores.map(item => Math.min(Math.max(item.testScore, 0), 10));


                        const data = {
                          labels: category_skills,
                          datasets: [
                            {
                              data: category_scores,
                              fill: true,
                              backgroundColor: "rgba(54, 162, 235, 0.2)",
                              borderColor: "rgb(54, 162, 235)",
                              pointBackgroundColor: "rgb(54, 162, 235)",
                              pointBorderColor: "#fff",
                              pointHoverBackgroundColor: "#fff",
                              pointHoverBorderColor: "rgb(54, 162, 235)",
                            },
                          ],
                        };

                        console.log(`${category.parameterName}_data`);

                        return skillScores.length > 0 ? (
                          <>
                            <div class="question-solved">
                              <div class="">
                                <div class={`${visibleQuestion === index && 'accordion-color-change'} d-block score-btns col-md-12 accordion-header border-bottom`}>
                                  <div class="col-12 d-flex justify-content-between summary-topic-header ">
                                    <div class="col-md-6">
                                      <h5 class="line-clamp-one w-140px">
                                        {category.displayName}
                                      </h5>
                                    </div>
                                    <div class="col-md-6 justify-content-end d-flex">
                                      {/*  <h5 class="me-4 mt-1">
                                        {parameterScore}{" "}
                                      </h5> */}
                                      <img
                                        src={
                                          visibleQuestion === index
                                            ? "../../Assets/Linqskills_Icons/circle-btn-up.svg"
                                            : "../../Assets/Linqskills_Icons/circle-btn-down.svg"
                                        }
                                        class="cursor-pointer float-end"
                                        onClick={() =>
                                          toggleQuestionVisibility(index)
                                        }
                                      ></img>
                                    </div>
                                  </div>
                                  {visibleQuestion === index && (
                                    <div class="mt-3 table-score  col-10 m-auto">
                                      <div class="graph-table">
                                        <div class="col-md-7 col-sm-12">
                                          <div class="graph-score">
                                            <Radar
                                              data={data}
                                              options={
                                                category.parameterName === "aptitude_skills" ? aptitude_skills_options :
                                                  communication_skills_options
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div class="col-md-5 col-sm-12 table-g  ">
                                          {skillScores.map((item) => {
                                            return (
                                              <div class="d-flex mb-3">
                                                <div class="d-flex align-items-baseline">
                                                  <h5 class="color-grey mt-0">{item.index}</h5>{" "}
                                                  <h5 class="bold-700 ms-3">
                                                    {item.testScore}
                                                  </h5>
                                                </div>
                                                <div class="ms-2 mt-1p">
                                                  <h5>{item.testName}</h5>
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null;
                      })}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div class="container-fluid body-wrapper bg-default">
                  <div class="container">
                    <div class={`course-indexcontent `}>
                      <div
                        className={`col-lg-9 col-sm-12 col-xs-12 mb-3 content-cards ${"sas"}`}
                      >
                        {" "}
                        <div className="primary-radius bg-white primary-radius mb-3">
                          {" "}
                          <div className="session-heading bg-body border-bottom">
                            <div className="d-flex">
                              <div class="pink-box">
                                <img
                                  src="../../Assets/Linqskills_Icons/assessment-multicolorRed.png"
                                  className=""
                                ></img>
                              </div>
                              <div class="heading-one">
                                <h5 className="mb-0 text-truncate">
                                  Assessment Score
                                </h5>
                              </div>
                            </div>
                          </div>
                          <CompletedTest
                            dontShowNavBtns={true}
                            ImgSrc="../../Assets/Linqskills_Icons/test-submited.svg"
                            Message="Your test score will be generated and shared with your college."
                            SecondMessage="To find out your score, please contact your college administrator"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }
}
