import Secondarybtn from "../Shared/Secondarybtn";
import CompletedReminder from "../MyCourses/CompletedReminder";
import PendingReminder from "../MyCourses/PendingReminder";
import FutureBatchReminder from "../MyCourses/futureBachReminder";
import moment from "moment";
import Translate from '../Shared/Translate';
var title = "", btnVal = "", btnDisable = "";

function ValidateCourseDate(dt) {
    // console.log("dt : ",dt)
    var currentDt = moment().format("DD-MMM-YYYY");
    var selectedDt = moment(dt, "DD/MM/YYYY").format("DD-MMM-YYYY");
    // console.log("moment(selectedDt).diff(moment(currentDt) : ", moment(selectedDt).diff(moment(currentDt), "days"));
    return moment(selectedDt).diff(moment(currentDt), "days");
}

function CompletedCourseTitle(certificationDate, isCertificateToShow, deliveryMode, examMode, markForGrading, isPassed, restrictCandidateAccess, activeStage, externalExam) {
    // console.log(`certificationDate=${certificationDate}  :: isCertificateToShow=${isCertificateToShow}  :: deliveryMode=${deliveryMode}  :: examMode=${examMode} :: markForGrading=${markForGrading} :: isPassed=${isPassed}  :: restrictCandidateAccess=${restrictCandidateAccess}`);
    //if (isPassed && (certificationDate != null) && isCertificateToShow && (deliveryMode != 4) && (examMode != "Theory") && markForGrading) {
    // console.log("ffffff: ", moment())
    // console.log("dddddddd : ", moment(certificationDate).isAfter(moment()))
    // console.log(`restrictCandidateAccess : ${restrictCandidateAccess}  ::: activeStage : ${activeStage}`)
    if (activeStage === 4 && restrictCandidateAccess === false) {
        title = "Training Completed On";
        btnVal = "View Course";
        btnDisable = (isPassed && (certificationDate != null) && isCertificateToShow && (deliveryMode != 4) && (examMode != "Theory") && markForGrading && !(moment(certificationDate).isAfter(moment()))) ? "disabled" : "";
    } else if (activeStage == 1) {
        btnVal = "View Score";
        if (externalExam) {
            title = "Test Completed ";

        }
    } else {
        title = "Course Completed On";
        btnVal = "Download Certificate";
        btnDisable = "";
    }
}

export default function CourseCard(props) {
    console.log("props in course card : ", props)

    // console.log("btnDisable res : ", btnDisable)
    // console.log("props.isDisabled : ", props.isDisabled);
    // console.log("props.isBatch : ", props.isBatch)
    if (props.type === "completed") {
        CompletedCourseTitle(props.certificationDate, props.isCertificateToShow, props.deliveryMode, props.examMode, props.markForGrading, props.isPassed, props.restrictCandidateAccess, props.activeStage, props.externalExam);
    }

    const validationOnBtn = (props) => {
        let temp = false
        // console.log("props in validation btn : ", props);
        if (props.isFormDetailsMandate && props.isCandidateFormSubmitted) {
            if (props.isBatch) {
                if (ValidateCourseDate(props.batchStartDate) > 0) {
                    // console.log("its means batch start date is in future disable the btn");
                    return true
                } else if (ValidateCourseDate(props.batchStartDate) <= 0) {
                    // console.log("batch start date is today or past date so we can access the course");
                    return temp;
                }
            } else {
                return true
            }
        } else {
            // console.log("else of props.isFormDetailsMandate && props.isCandidateFormSubmitted")
            if (!props.isFormDetailsMandate) {
                if (props.isBatch) {
                    // console.log("if is batch true here 1");
                    if (ValidateCourseDate(props.batchStartDate) > 0) {
                        // console.log("its means batch start date is in future disable the btn");
                        return true
                    } else if (ValidateCourseDate(props.batchStartDate) <= 0) {
                        // console.log("batch start date is today or past date so we can access the course");
                        return temp;
                    }
                } else {
                    console.log("in else if isbatch true here 1");
                    if (props.isProgram == false && props.programId !== "") {
                        console.log("this card is for program so is batch is not mandate")
                        //    return true
                    } else {
                        // console.log("here in this")
                        return true
                    }
                }
            } else if (props.isFormDetailsMandate && !props.isCandidateFormSubmitted) {
                return false
            }
        }
    }

    const BtnIsDisabled = () => {
        console.log("BtnIsDisabled : calling");
    }

    const element = props.type === "completed" ? (
        <>
            {
                props.isProgram == true ?
                    <div className="card card-modify ss">

                        <img src={props.courseIcon} class="card-img" />

                        <div class="card-body cardbody-height">

                            <h5 class="card-title line-clamp-three">{props.courseName}</h5>

                            <div class="course-content">
                                {
                                    props.activeStage == "4" && props.courseCompletedOn != "" ?
                                        <>
                                            {/* this is when course is completed  */}
                                            <div>
                                                <CompletedReminder txtValue={"Course Completed On"} />
                                            </div>
                                            <div class="calendar-text mt-2">
                                                {props.courseCompletedOn == null ? "" : <img src="../../Assets/Linqskills_icons/calendar-Multicolorgreen.svg" class="" />}
                                                <h6>{props.courseCompletedOn == null ? "" : moment(props.courseCompletedOn).format('DD MMM YYYY |  h:mm A')}</h6>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {/* this is when ega is completed */}
                                            <div class="event-details 22d2">
                                                <div>
                                                    {props.externalExam === true ? <PendingReminder txtValue={title} externalExam={props.externalExam} /> : <CompletedReminder txtValue={title} />}
                                                </div>
                                                {(props.externalExam != true) && <div class="calendar-text mt-2">
                                                    <img src="../../Assets/Linqskills_icons/calendar-Multicolorgreen.svg" class="" />
                                                    <h6>{props.courseCompletedOn !== null ? "" : moment(props.courseCompletedOn).format('DD MMM YYYY |  h:mm A')}</h6>
                                                </div>}
                                                {
                                                    (props.externalExam == true) && <div class="calendar-text mt-2">
                                                        {
                                                            props.courseCompletedOn == null ? "" :
                                                                <>
                                                                    <img src="../../Assets/Linqskills_icons/calendar-Multicolorgreen.svg" class="" />
                                                                    <h6>{moment(props.courseCompletedOn).format('DD MMM YYYY |  h:mm A')}</h6>
                                                                </>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </>
                                }

                            </div>
                            <div class="course-action pt-3">
                                <Secondarybtn buttonID="downloadCertificate" isDisabled={btnDisable} buttonVal={btnVal} certificate={props.certificate} buttonClick={props.buttonClick}></Secondarybtn>
                                {/* <button id="btnSubmit" type="button" className="text-uppercase continue-popup secondary-btn w-100" onClick={props.buttonClick}>{btnVal}
                        <img src="../../Assets/Linqskills_Icons/arrow_forwardblue.svg" className=""></img>
                    </button> */}
                            </div>
                        </div>
                    </div >
                    : props.isProgram == false && props.programId == "" ?
                        <div className="card card-modify 23">
                            <img src={props.courseIcon} class="card-img" />
                            {/* <img src="../../Assets/Linqskills_Icons/sessionstatus-submitted.svg"class="pe-2 completed-card"></img> */}


                            <div class="card-body cardbody-height">
                                <h5 class="card-title line-clamp-three">{props.courseName}</h5>
                                <div class="course-content">
                                    {
                                        props.activeStage == "4" && props.courseCompletedOn != "" ?
                                            <>
                                                {/* this is when course is completed  */}
                                                <div>
                                                    <CompletedReminder txtValue={"Course Completed On"} />
                                                </div>
                                                <div class="calendar-text mt-2">
                                                    {props.courseCompletedOn == null ? "" : <img src="../../Assets/Linqskills_icons/calendar-Multicolorgreen.svg" class="" />}
                                                    <h6>{props.courseCompletedOn == null ? "" : moment(props.courseCompletedOn).format('DD MMM YYYY |  h:mm A')}</h6>
                                                </div>
                                            </>
                                            :
                                            <>
                                                {/* this is when ega is completed */}
                                                <div class="event-details 22d2">
                                                    <div>
                                                        {props.externalExam === true ? <PendingReminder txtValue={title} externalExam={props.externalExam} /> : <CompletedReminder txtValue={title} />}
                                                    </div>
                                                    {(props.externalExam != true) && <div class="calendar-text mt-2">
                                                        <img src="../../Assets/Linqskills_icons/calendar-Multicolorgreen.svg" class="" />
                                                        <h6>{props.courseCompletedOn !== null ? "" : moment(props.courseCompletedOn).format('DD MMM YYYY |  h:mm A')}</h6>
                                                    </div>}
                                                    {
                                                        (props.externalExam == true) && <div class="calendar-text mt-2">
                                                            {
                                                                props.courseCompletedOn == null ? "" :
                                                                    <>
                                                                        <img src="../../Assets/Linqskills_icons/calendar-Multicolorgreen.svg" class="" />
                                                                        <h6>{moment(props.courseCompletedOn).format('DD MMM YYYY |  h:mm A')}</h6>
                                                                    </>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </>
                                    }

                                </div>
                                <div class="course-action pt-3">
                                    <Secondarybtn buttonID="downloadCertificate" isDisabled={btnDisable} buttonVal={btnVal} certificate={props.certificate} buttonClick={props.buttonClick}></Secondarybtn>
                                    {/* <button id="btnSubmit" type="button" className="text-uppercase continue-popup secondary-btn w-100" onClick={props.buttonClick}>{btnVal}
                    <img src="../../Assets/Linqskills_Icons/arrow_forwardblue.svg" className=""></img>
                </button> */}
                                </div>
                            </div>
                        </div > : ""
            }
        </>
    ) : (
        <div class="card card-modify">
            <img src={props.courseIcon} class="card-img" />
            {
                props.isProgram == true && (props.markedCompleted == "1" || props.batchCompleted == 1) ?
                    <img src="../../Assets/Linqskills_Icons/sessionstatus-submitted.svg" class="pe-2 completed-card"></img> : ""
            }

            <div class="card-body cardbody-height"  >
                {/*<span class="course-type d-inline-block text-truncate w-50 mt-2" data-bs-toggle="tooltip" data-bs-placement="top" title={sectorName}>{sectorName}</span>*/}

                {
                    props.isProgram == false && props.programId != "" ?
                        <h5 class="card-title line-clamp-three">{props.programName}</h5>
                        :
                        <h5 class="card-title line-clamp-three">{props.courseName}</h5>
                }

                <div class="course-content">
                    {
                        ((props.isCandidateFormSubmitted == false) && (props.isFormDetailsMandate == false)) ?
                            <>
                                {
                                    props.programId !== "" ?
                                        <>
                                            {
                                                props.batchCompleted == 1 || props.courseCompleted || props.markedCompleted == "1" ?
                                                    <>
                                                        <div>
                                                            <CompletedReminder txtValue={props.markedCompleted == "1" ? "Course Completed On" : props.batchCompleted == 1 ? "Batch Completed On" : ""} />
                                                        </div>
                                                        <div class="calendar-text mt-2">
                                                            <img src="../../Assets/Linqskills_icons/calendar-Multicolorgreen.svg" class="" />
                                                            {/* <h6>{props.courseCompletedOn == null ? "" : moment(props.courseCompletedOn).format('DD MMM YYYY | hh:mm A')}</h6> */}
                                                            <h6>{props.markedCompleted == "1" ? props.courseCompletedOn : props.batchCompleted == 1 ? props.batchEndDate : ""}</h6>
                                                        </div>
                                                    </> :
                                                    props.batchID == 0 ?
                                                        <>
                                                            <div class="course-details">
                                                                <div class="duration ">
                                                                    <img src="../../Assets/Linqskills_Icons/time-mutlicolorgreen.svg" class="" /><p>{props.duration}</p>
                                                                </div>
                                                                <div >
                                                                    <PendingReminder txtValue={"AWAITING TRAINING DETAILS"} />
                                                                </div>

                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div class="course-details">
                                                                <div class="duration ">
                                                                    <img src="../../Assets/Linqskills_Icons/time-mutlicolorgreen.svg" class="" /><p>{props.duration}</p>
                                                                </div>

                                                                <p class="line-clamp-three m-0 mt-2" data-bs-toggle="tooltip" data-bs-placement="top" title={props.description}>
                                                                    <span class="">{props.description}</span>
                                                                </p>
                                                            </div>
                                                        </>
                                            }
                                        </>
                                        :
                                        <>
                                            <div class="course-details">
                                                <div class="duration ">
                                                    <img src="../../Assets/Linqskills_Icons/time-mutlicolorgreen.svg" class="" /><p>{props.duration}</p>
                                                </div>

                                                <p class="line-clamp-three m-0 mt-2" data-bs-toggle="tooltip" data-bs-placement="top" title={props.description}>
                                                    <span class="">{props.description}</span>
                                                </p>
                                            </div>
                                        </>
                                }

                            </> :
                            <>
                                {
                                    props.activeStage == "1" ?
                                        <>
                                            {
                                                (props.egaPendingCnt > 0) ?
                                                    <>
                                                        <div class="event-details p-0">
                                                            <div class="event-details ">
                                                                <div >
                                                                    <PendingReminder txtValue={"PROFICIENCY TEST PENDING"} />
                                                                </div>
                                                                <div class="calendar-text mt-2">
                                                                    <img src="../../Assets/Linqskills_icons/calendar-Multicolorgreen.svg" class="" />
                                                                    <h6>{props.egaPendingCnt} Pending</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> :
                                                    <>
                                                        <div class="event-details p-0">
                                                            <div class="event-details ">
                                                                <div >
                                                                    <PendingReminder txtValue={"PROFICIENCY TEST PENDING"} />
                                                                </div>
                                                                <div class="calendar-text mt-2">
                                                                    <img src="../../Assets/Linqskills_icons/calendar-Multicolorgreen.svg" class="" />
                                                                    <h6>1 Pending</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </> :
                                        <>
                                            {
                                                ((props.batchID === 0 && props.programId == "")) ?
                                                    <>
                                                        <div class="course-details">
                                                            <div class="duration ">
                                                                <img src="../../Assets/Linqskills_Icons/time-mutlicolorgreen.svg" class="" /><p>{props.duration}</p>
                                                            </div>
                                                            <div >
                                                                <PendingReminder txtValue={"AWAITING TRAINING DETAILS"} />
                                                            </div>

                                                        </div>
                                                    </> : ((props.batchID !== 0 && props.programId == "")) ?
                                                        <>
                                                            <div class="course-details">
                                                                {ValidateCourseDate(props.batchStartDate) > 0 ?
                                                                    <>
                                                                        <CompletedReminder txtValue={"COURSE TRAINING STARTS ON"} />
                                                                        <div class="calendar-text mt-2">
                                                                            <img src="../../Assets/Linqskills_icons/calendar-Multicolorgreen.svg" class="" />
                                                                            {/* <h6>{props.courseCompletedOn == null ? "" : moment(props.courseCompletedOn).format('DD MMM YYYY | hh:mm A')}</h6> */}
                                                                            <h6>{props.batchStartDate}</h6>
                                                                        </div>

                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div class="duration ">
                                                                            <img src="../../Assets/Linqskills_Icons/time-mutlicolorgreen.svg" class="" /><p>{props.duration}</p>
                                                                        </div>
                                                                        <p class="line-clamp-three m-0 mt-2" data-bs-toggle="tooltip" data-bs-placement="top" title={props.description}>
                                                                            <span class="">{props.description}</span>
                                                                        </p>
                                                                    </>
                                                                }


                                                            </div>
                                                        </> : ((props.programId !== "")) ?
                                                            <>
                                                                <div class="course-details">
                                                                    {ValidateCourseDate(props.batchStartDate) > 0 ?
                                                                        <>
                                                                            <CompletedReminder txtValue={"PROGRAM TRAINING STARTS ON"} />
                                                                            <div class="calendar-text mt-2">
                                                                                <img src="../../Assets/Linqskills_icons/calendar-Multicolorgreen.svg" class="" />
                                                                                {/* <h6>{props.courseCompletedOn == null ? "" : moment(props.courseCompletedOn).format('DD MMM YYYY | hh:mm A')}</h6> */}
                                                                                <h6>{props.batchStartDate}</h6>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {
                                                                                props.batchCompleted == 1 || props.courseCompleted || props.markedCompleted == "1" ?
                                                                                    <>
                                                                                        <div>
                                                                                            <CompletedReminder txtValue={props.markedCompleted == "1" ? "Course Completed On" : props.batchCompleted == 1 ? "Batch Completed On" : ""} />
                                                                                        </div>
                                                                                        <div class="calendar-text mt-2">
                                                                                            <img src="../../Assets/Linqskills_icons/calendar-Multicolorgreen.svg" class="" />
                                                                                            {/* <h6>{props.courseCompletedOn == null ? "" : moment(props.courseCompletedOn).format('DD MMM YYYY | hh:mm A')}</h6> */}
                                                                                            <h6>{props.markedCompleted == "1" ? props.courseCompletedOn : props.batchCompleted == 1 ? props.batchEndDate : ""}</h6>
                                                                                        </div>
                                                                                    </> :
                                                                                    <>
                                                                                        <div class="course-details">
                                                                                            <div class="duration ">
                                                                                                <img src="../../Assets/Linqskills_Icons/time-mutlicolorgreen.svg" class="" /><p>{props.duration}</p>
                                                                                            </div>

                                                                                            <p class="line-clamp-three m-0 mt-2" data-bs-toggle="tooltip" data-bs-placement="top" title={props.description}>
                                                                                                <span class="">{props.description}</span>
                                                                                            </p>
                                                                                        </div>
                                                                                    </>
                                                                            }
                                                                        </>
                                                                    }
                                                                </div>
                                                            </> : ""
                                            }
                                        </>
                                }
                            </>
                    }

                </div>
                <div class="course-action pt-3">
                    <button type="button" onClick={validationOnBtn(props) ? BtnIsDisabled() : props.buttonClick} class={validationOnBtn(props) ? "w-100 disabled-btn" : props.markedCompleted == "1" || props.batchCompleted == 1 ? "text-uppercase continue-popup secondary-btn w-100" : "primary-btn w-100"} disabled={props.isLoader ? true : validationOnBtn(props) ? true : false}>

                        <Translate textKey={props.buttonValue} />
                        <img src="../../Assets/Linqskills_icons/arrow_forwardwhite.svg" class="" />
                    </button>
                </div>

            </div>
        </div>

    );

    return (
        <div>
            {element}
        </div>
    )
}



// {

//     ((props.batchID === 0) && (props.egaPendingCnt === 0) && (props.isCandidateFormSubmitted == false)) ?
//         <>
//             <div class="course-details">
//                 <div class="duration m-0 mt-2">
//                     <img src="../../Assets/Linqskills_Icons/time-mutlicolorgreen.svg" class="" /><p>{props.duration}</p>
//                 </div>

//                 <p class="line-clamp-three m-0 mt-2" data-bs-toggle="tooltip" data-bs-placement="top" title={props.description}>
//                     <span class="">{props.description}</span>
//                 </p>
//             </div>
//         </> : ((props.batchID !== 0) && (props.vcStartTime == null) && (ValidateCourseDate(props.batchStartDate) <= 0)) ?
//             <>
//                 <div class="course-details">
//                     <div class="duration m-0 mt-2">
//                         <img src="../../Assets/Linqskills_Icons/time-mutlicolorgreen.svg" class="" /><p>{props.duration}</p>
//                     </div>

//                     <p class="line-clamp-three m-0 mt-2" data-bs-toggle="tooltip" data-bs-placement="top" title={props.description}>
//                         <span class="">{props.description}</span>
//                     </p>
//                 </div>
//             </>
//             :
//             ((ValidateCourseDate(props.batchStartDate) > 0) &&
//                 ((props.activeStage === 4) && (props.vcStartTime == null))) &&
//             (<>
//                 <div class="course-details">
//                     <div class="duration m-0 mt-2">
//                         <img src="../../Assets/Linqskills_Icons/time-mutlicolorgreen.svg" class="" /><p>{props.duration}</p>
//                     </div>

//                     <p class="line-clamp-three m-0 mt-2" data-bs-toggle="tooltip" data-bs-placement="top" title={props.description}>
//                         <span class="">{props.description}</span>
//                     </p>
//                 </div>
//             </>)
// }
// {
//     (props.egaPendingCnt > 0) ?

//         <div class="event-details p-0">
//             {/* <div class="reminder">
//                     <img src="../../Assets/Linqskills_Icons/reminder-clock.svg" class="" />

//                     <span className="">Eligibility test pendxing </span>

//                 </div> */}
//             {
//                 props.isCandidateFormSubmitted == false && props.isFormDetailsMandate == true ?
//                     // <>
//                     //     <div class="course-details">
//                     //         <div class="duration m-0 mt-2">
//                     //             <img src="../../Assets/Linqskills_Icons/time-mutlicolorgreen.svg" class="" /><p>{props.duration} herere</p>
//                     //         </div>

//                     //         <p class="line-clamp-three m-0 mt-2" data-bs-toggle="tooltip" data-bs-placement="top" title={props.description}>
//                     //             <span class="">{props.description}</span>
//                     //         </p>
//                     //     </div>
//                     // </>
//                     ""
//                     :
//                     <>
//                         <div class="event-details ">
//                             <div >
//                                 <PendingReminder txtValue={"PROFICIENCY TEST PENDING"} />
//                             </div>
//                             <div class="calendar-text mt-2">
//                                 <img src="../../Assets/Linqskills_icons/calendar-Multicolorgreen.svg" class="" />
//                                 <h6>{props.egaPendingCnt} Pending</h6>
//                             </div>
//                         </div>

//                     </>
//             }
//         </div>
//         :
//         (props.activeStage === 4) && (ValidateCourseDate(props.batchStartDate) > 0) ?
//             // <>
//             //     <FutureBatchReminder batchStartsOn={moment(props.batchStartDate, 'DD/MM/YYYY').format('DD MMM YYYY')} />
//             // </>
//             ""
//             :
//             (props.activeStage === 4) && (props.vcStartTime != null) &&
//             <>

//                 <div class="event-details 33">
//                     <div class="reminder">
//                         <img src="../../Assets/Linqskills_Icons/reminder-clock.svg" class=""></img>
//                         <span className="">Online class scheduled on</span>


//                     </div>
//                     <div class="calendar-text mt-2">
//                         <img src="../../Assets/Linqskills_icons/calendar-Multicolorgreen.svg" class="" />
//                         <h6>{props.vcStartTime}</h6>
//                     </div>
//                 </div>


//             </>
// }